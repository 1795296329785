import {
  AsyncSelect,
  Button,
  Select,
  SplitTextField,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { Country, State } from "api/resources/global/types"
import { APIError } from "api/utils"
import SlidingSheet from "components/SlidingSheet"
import { Cancel } from "iconoir-react"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import useCustomEvent from "utils/hooks/useCustomEvent"
import useDetectCountry from "utils/hooks/useDetectCountry"
import { useResource } from "utils/hooks/useResource"

import styles from "./AddSchoolSheet.module.css"

type Props = {
  open: boolean
  handleClose: () => void
}
type Option = { label: string; value: string; __isNew__?: boolean }
type AddSchoolForm = {
  schoolName: string
  schoolAddress: string
  schoolEmail: string
  branch?: string
  board?: string
  pinCode?: string
  state: string
  country: string
  city: Option | null
  contactPersonName: string
  contactPersonPhoneNumber: {
    code?: string
    number?: string
  }
}

const getInitialValues = (): AddSchoolForm => ({
  schoolName: "",
  schoolAddress: "",
  schoolEmail: "",
  branch: "",
  board: "",
  pinCode: "",
  state: "",
  country: "",
  city: null,
  contactPersonName: "",
  contactPersonPhoneNumber: { code: undefined, number: "" },
})

function debounce(fn: (...args: any[]) => void, delay = 250) {
  // eslint-disable-next-line no-undef
  let timeout: NodeJS.Timeout

  return (...args: any[]) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      fn(...args)
    }, delay)
  }
}

const AddSchoolSheet = ({ open, handleClose }: Props) => {
  const { dispatch: callEventListener } = useCustomEvent("schoolList")
  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    getValues,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<AddSchoolForm>({ defaultValues: getInitialValues() })
  const { countries } = useResource(["countries"])

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null)
  const [selectedState, setSelectedState] = useState<State | null>(null)
  const [statesLoading, setStatesLoading] = useState(false)
  const [cities, setCities] = useState<Option[]>([])
  const [states, setStates] = useState<State[]>([])

  const onSubmit = handleSubmit(data => {
    clearErrors()
    mutate(data)
  })

  const onClose = () => {
    reset(getInitialValues())
    handleClose()
  }

  const currentCountry = useDetectCountry()

  useEffect(() => {
    const code = countryCodes.find(
      i => i.code === currentCountry?.isoCode
    )?.dialCode

    if (code) setValue("contactPersonPhoneNumber.code", code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, open])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadCities = useCallback(
    debounce(async (inputValue: string, callback: (options: any) => void) => {
      if (inputValue.length > 2) {
        const res = await api.global.listCities({
          urlParams: {
            stateId: getValues().state,
          },
          params: {
            page: -1,
            search: inputValue,
          },
        })
        if (res.isSuccessful) {
          callback(
            res.data.map(item => ({ label: item.name, value: item.uuid }))
          )
        }
      }
    }, 300),
    [getValues]
  )

  const options = useMemo(
    () =>
      countryCodes.map(({ dialCode }) => ({
        value: dialCode,
        label: dialCode,
      })),
    []
  )

  const { mutate } = useMutation({
    mutationFn: (formData: AddSchoolForm) => {
      const data = {
        ...formData,
        board: formData.board || undefined,
        branch: formData.branch || undefined,
        pinCode: formData.pinCode || undefined,
        contactPersonPhoneNumber: `${formData.contactPersonPhoneNumber.code}${formData.contactPersonPhoneNumber.number}`,
        city: formData.city?.value,
      }
      return api.partner.dashboard.addSchool({
        data: data,
      })
    },
    onSuccess: () => {
      callEventListener({ delay: 500 })
      onClose()
      toast.success("School added successfully")
    },
    onError: err => {
      if (err instanceof APIError) {
        if (err.errors.fieldErrors?.user) {
          toast.error(err.errors.fieldErrors?.user[0])
          return
        }

        handleErrors(err, { setter: setError })
      }
    },
  })

  useEffect(() => {
    async function getState() {
      if (selectedCountry) {
        setStatesLoading(true)
        const statesList = await api.global.listStates({
          urlParams: { countryId: selectedCountry.uuid },
          params: { page: "-1" },
        })
        if (statesList.isSuccessful) {
          setStates(statesList.data)
          setStatesLoading(false)
        }
      }
    }

    getState()
  }, [selectedCountry])

  return (
    <SlidingSheet
      from="end"
      open={open}
      isDismissible={false}
      onClose={onClose}
      className="px-3 pt-4 pb-3 h-screen flex flex-col sm:max-w-[420px] w-full"
    >
      <Button
        variant="text"
        color="black"
        onClick={onClose}
        startAdornment={<Cancel />}
        className="-ml-1 mb-2.5"
      >
        Close
      </Button>
      <Typography variant="title3" className="mb-3">
        Add School
      </Typography>

      <form
        onSubmit={onSubmit}
        className="flex flex-col justify-between gap-3 h-fit"
      >
        <div className="flex flex-col gap-3">
          <TextField
            label="School Name"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.schoolName)}
            helperText={errors.schoolName?.message}
            placeholder="Ex. Acme Public High School"
            fullWidth
            {...register("schoolName", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="School Address"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.schoolAddress)}
            helperText={errors.schoolAddress?.message}
            placeholder="Ex. 6100, North Avenue"
            fullWidth
            {...register("schoolAddress", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="country"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="Country"
                fullWidth
                error={Boolean(errors.country)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.country?.message}
                inputLabelProps={{ required: true }}
                options={countries}
                placeholder="Select Country"
                value={
                  value ? countries.find(item => item.uuid === value) : null
                }
                onBlur={onBlur}
                onChange={val => {
                  if (val) {
                    setSelectedCountry(val)
                    onChange(val.uuid)
                  }

                  setValue("city", null)
                  setValue("state", "")
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="state"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="State"
                error={Boolean(errors.state)}
                getOptionLabel={({ name }) => name}
                getOptionValue={({ uuid }) => uuid}
                helperText={errors.state?.message}
                inputLabelProps={{ required: true }}
                isLoading={statesLoading}
                options={states}
                placeholder="State"
                value={value ? states.find(item => item.uuid === value) : null}
                fullWidth
                onBlur={onBlur}
                onChange={val => {
                  if (val) {
                    setSelectedState(val)
                    onChange(val.uuid)
                  }

                  setValue("city", null)
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />

          <Controller
            control={control}
            name="city"
            render={({ field: { onChange, onBlur, value } }) => (
              <AsyncSelect
                defaultOptions={cities}
                error={Boolean(errors.city)}
                filterOption={() => true}
                helperText={errors.city?.message}
                inputLabelProps={{
                  required: true,
                }}
                isDisabled={!selectedCountry || !selectedState}
                label="City/Town"
                loadOptions={loadCities}
                message="Enter minimum 3 characters to search…"
                placeholder="Start typing..."
                value={value}
                fullWidth
                isClearable
                onBlur={onBlur}
                onChange={(val, actionMeta) => {
                  if (actionMeta.action === "create-option") {
                    setCities(prevState => [...prevState, actionMeta.option])
                  }
                  onChange(val)
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <TextField
            label="Pincode"
            error={Boolean(errors.pinCode)}
            helperText={errors.pinCode?.message}
            placeholder="Ex: 110051"
            fullWidth
            {...register("pinCode")}
          />
          <TextField
            label="School Email"
            error={Boolean(errors.schoolEmail)}
            helperText={errors.schoolEmail?.message}
            placeholder="Ex. contact@aphs.edu"
            fullWidth
            inputLabelProps={{ required: true }}
            {...register("schoolEmail", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="Branch (optional)"
            error={Boolean(errors.branch)}
            helperText={errors.branch?.message}
            placeholder="Ex. Delhi Branch"
            fullWidth
            {...register("branch")}
          />
          <TextField
            label="Board"
            error={Boolean(errors.board)}
            helperText={errors.board?.message}
            placeholder="Ex. CBSE"
            fullWidth
            {...register("board")}
          />
          <TextField
            label="Name of the Contact Person"
            error={Boolean(errors.contactPersonName)}
            inputLabelProps={{ required: true }}
            helperText={errors.contactPersonName?.message}
            placeholder="Ex. John Doe"
            fullWidth
            {...register("contactPersonName", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="contactPersonPhoneNumber"
            render={({ field }) => (
              <SplitTextField
                className={styles.phoneNumber}
                inputLabelProps={{ required: true }}
                label="Phone Number"
                error={Boolean(errors.contactPersonPhoneNumber)}
                helperText={errors.contactPersonPhoneNumber?.message}
                selectProps={{
                  options,
                  value: { value: field.value.code, label: field.value.code },
                  onChange: val => {
                    clearErrors()
                    if (val)
                      setValue("contactPersonPhoneNumber.code", val.value)
                  },
                }}
                textFieldProps={{
                  type: "number",
                  placeholder: "Enter phone number",
                  value: field.value.number ?? undefined,
                  onChange: val => {
                    clearErrors()
                    setValue(
                      "contactPersonPhoneNumber.number",
                      val.target.value
                    )
                  },
                }}
                fullWidth
              />
            )}
            rules={{
              validate: v => {
                if (!v) return "Required"
                if (!v.code) return "Required"
                if (!v.number) return "Required"
              },
              required: {
                value: true,
                message: "Required",
              },
            }}
          />
        </div>
        <Button type="submit" className="!rounded-full mb-2" fullWidth>
          Add
        </Button>
      </form>
    </SlidingSheet>
  )
}

export default AddSchoolSheet
