import { useEffect } from "react"

export const useScrollShiftOffset = (enabled: boolean) => {
  useEffect(() => {
    if (enabled) {
      // Accounting for content shift due to the scrollbar
      document.body.style.paddingInlineEnd = `${
        window.innerWidth - document.documentElement.clientWidth
      }px`
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "initial"
      document.body.style.paddingInlineEnd = ""
    }

    return () => {
      document.body.style.overflow = "initial"
      document.body.style.paddingInlineEnd = ""
    }
  }, [enabled])
}
