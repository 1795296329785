import { Container, Divider } from "@suraasa/placebo-ui"
import CompletedRegistrations from "components/dashboard/CompletedRegistrations"
import Incomplete from "components/dashboard/Incomplete"
import SearchField from "components/SearchField"
import Tabs from "components/shared/tabs/Tabs"
import useTabs, { Tabs as TabsType } from "components/shared/tabs/useTabs"
import React, { useState } from "react"
import { ValueOf } from "types"

import SchoolTable from "./SchoolTable"

const TABS = {
  incomplete: "Incomplete Registrations",
  completedRegistrations: "Completed Registrations",
  school: "School",
}

const getActiveTabKey = (tab: ValueOf<typeof TABS> | null) => {
  if (tab === "Incomplete") return "incomplete"
  if (tab === "Completed Registrations") return "completedRegistrations"
  return "school"
}

const placeholders = {
  incomplete: "Search by Name",
  completedRegistrations: "Search by Name",
  school: "Search by School Name",
}

const ParticipantsTable = () => {
  const [searchData, setSearchData] = useState({
    incomplete: "",
    completedRegistrations: "",
    school: "",
  })

  const [tabs, setTabs] = useState<TabsType>([
    {
      name: TABS.school,
      content: SchoolTable,
      count: 0,
    },
    {
      name: TABS.completedRegistrations,
      content: CompletedRegistrations,
      count: 0,
    },
    { name: TABS.incomplete, content: Incomplete, count: 0 },
  ])

  const [activeTab, setActiveTab] = useTabs({
    tabs: tabs,
    initialTab: tabs[0].name,
    useSearchParams: false,
  })

  const onSearch = (data: string) => {
    setSearchData(prevState => ({
      ...prevState,
      [getActiveTabKey(activeTab.name)]: data,
    }))
  }

  return (
    <>
      <Container>
        <div className="flex justify-between">
          <Tabs activeTab={activeTab} tabs={tabs} onChange={setActiveTab} />
          <div className="hidden sm:block">
            <SearchField
              placeholder={placeholders[getActiveTabKey(activeTab.name)]}
              value={searchData[getActiveTabKey(activeTab.name)]}
              onSearch={onSearch}
            />
          </div>
        </div>
        <Divider weight="light" />
        <div className="block mt-2 sm:hidden">
          <SearchField
            placeholder={placeholders[getActiveTabKey(activeTab.name)]}
            value={searchData[getActiveTabKey(activeTab.name)]}
            onSearch={onSearch}
          />
        </div>
      </Container>
      <Container className="mt-1">
        {tabs.map(tab => (
          <tab.content
            key={tab.name}
            isActive={tab.name === activeTab.name}
            onCountUpdate={(count: number) => {
              setTabs(prevTabs => {
                return prevTabs.map(x =>
                  x.name === tab.name ? { ...x, count } : x
                )
              })
            }}
            search={searchData[getActiveTabKey(tab.name)]}
          />
        ))}
      </Container>
    </>
  )
}

export default ParticipantsTable
