import { CircularProgress } from "@suraasa/placebo-ui"
import React from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  progressOverlay: {
    position: "absolute",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    left: 0,
    top: 0,
    zIndex: theme.zIndex.dialog,
    borderRadius: theme.spacing(0.5),
  },
}))

type Props = {
  /**
   * if true renders `Suraasa Butterfly` instead of `CircularProgress`
   */
  showLogo?: boolean
  children?: JSX.Element | React.ReactNode
  opacity?: number
}

const LoadingOverlay = ({
  opacity = 0.1,
  children,
  showLogo = false,
}: Props) => {
  const classes = useStyles()

  return (
    <div
      className={classes.progressOverlay}
      style={{
        background: `rgba(0, 0, 0, ${opacity})`,
      }}
    >
      {showLogo ? (
        <img
          alt="ito logo"
          height="100"
          width="100"
          src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
        />
      ) : (
        <CircularProgress />
      )}
      {children}
    </div>
  )
}

export default LoadingOverlay
