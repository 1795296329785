import "@suraasa/placebo-ui/dist/esm/components/Toast/toast.css"
import "./index.css"

import { CssBaseline, theme } from "@suraasa/placebo-ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import GetActions from "components/auth/GetActions"
import GlobalState from "components/GlobalState"
import ErrorPage from "components/shared/ErrorPage"
import PrivateRoute from "components/shared/PrivateRoute"
import React, { useEffect } from "react"
import { ThemeProvider } from "react-jss"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { getAuthInfo } from "utils/auth"
import { GA } from "utils/googleAnalytics"
import { routes } from "utils/routes"
import SSO from "views/auth/SSO"
import Home from "views/Home"
import PartnerDashboard from "views/Partner/Dashboard"
import PartnerTeamMemberDashboard from "views/PartnerTeamMember/Dashboard"
import SchoolDashboard from "views/School/Dashboard"
import SchoolRegistration from "views/SchoolRegistration"
import SentryTest from "views/SentryTest"

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        /**
         * We don't want failed requests to be retried by default
         * We can enable this on a per-API basis
         */
        retry: false,
      },
    },
  })

  useEffect(() => {
    const authInfo = getAuthInfo()
    GA.init()

    if (authInfo) GA.setUserId(authInfo.user.uuid)
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ToastContainer position="bottom-center" />
      <Router>
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route element={<SSO />} path="/" />
            <Route element={<SentryTest />} path={routes.error} />

            <Route element={<GlobalState />} path="/">
              <Route element={<PrivateRoute />} path="/">
                <Route element={<GetActions />} path="/">
                  <Route element={<Home />} path={routes.home} />
                  <Route
                    element={<SchoolDashboard />}
                    path={routes.school.dashboard}
                  />
                  <Route
                    element={<SchoolRegistration />}
                    path={routes.school.schoolRegistration}
                  />
                  <Route
                    element={<PartnerDashboard />}
                    path={routes.partner.dashboard}
                  />
                  <Route
                    element={<SchoolRegistration />}
                    path={routes.partner.schoolRegistration}
                  />
                  <Route
                    element={<PartnerTeamMemberDashboard />}
                    path={routes.partnerTeamMember.dashboard}
                  />
                  <Route
                    element={<SchoolRegistration />}
                    path={routes.partnerTeamMember.schoolRegistration}
                  />
                </Route>
              </Route>
              <Route element={<ErrorPage errorCode={404} />} path="*" />
            </Route>

            <Route element={<ErrorPage errorCode={404} />} path="*" />
          </Routes>
        </QueryClientProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
