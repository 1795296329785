import axios from "api/axios"
import { Config, NoContentType, PaginatedAPIResponse } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { PartnerSchool, TeamMember } from "./types"
import { urls } from "./urls"

export default {
  dashboard: {
    schoolList: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<PartnerSchool[]>> => {
      try {
        const res = await axios.get(urls.dashboard.listSchools(), {
          params,
        })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    teamList: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<TeamMember[]>> => {
      try {
        const res = await axios.get(urls.dashboard.teamList(), {
          params,
        })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    addSchool: async ({
      data,
    }: Config): Promise<PaginatedAPIResponse<NoContentType>> => {
      try {
        const res = await axios.post(urls.dashboard.addSchool(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    addTeamMember: async ({
      data,
    }: Config): Promise<PaginatedAPIResponse<NoContentType>> => {
      try {
        const res = await axios.post(urls.dashboard.addTeamMember(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    editTeamMembers: async ({
      params,
      data,
    }: Config): Promise<PaginatedAPIResponse<NoContentType>> => {
      try {
        const res = await axios.put(
          urls.dashboard.editTeamMember(params.slug),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
