import clsx from "clsx"
import React, { PropsWithChildren } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(theme => ({
  tab: {
    ...theme.typography.body,
    // color: theme.colors.onSurface[500],
    marginRight: theme.spacing(4),
    padding: theme.spacing(0, 0.25, 1, 0.25),
    flexShrink: 0,
    whiteSpace: "nowrap",
    "&$active": {
      ...theme.typography.strong,
      // color: theme.colors.primary[500],
      // borderBottom: `2px solid ${theme.colors.primary[500]}`,
    },
  },
  primaryTab: {
    color: theme.colors.onSurface[500],
    "&$active": {
      color: theme.colors.primary[500],
      borderBottom: `2px solid ${theme.colors.primary[500]}`,
    },
  },
  blackTab: {
    color: theme.colors.onSurface[500],
    "&$active": {
      color: theme.colors.secondary[900],
      borderBottom: `2px solid ${theme.colors.secondary[900]}`,
    },
  },
  active: {},
}))

type Props = {
  isActive: boolean
  color?: "primary" | "black"
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

const TabItem = ({
  children,
  isActive,
  color = "primary",
  ...props
}: PropsWithChildren<Props>) => {
  const classes = useStyles()
  return (
    <button
      className={clsx(classes.tab, {
        [classes.active]: isActive,
        [classes.primaryTab]: color === "primary",
        [classes.blackTab]: color === "black",
      })}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default TabItem
