import * as Sentry from "@sentry/react"
import { MoEngage } from "@suraasa/moengage"
import SentryErrorPage from "components/shared/SentryErrorPage"
import React from "react"
import { createRoot } from "react-dom/client"
import { getAuthInfo } from "utils/auth"
import { checkEnvVars } from "utils/helpers"

import { version } from "../package.json"
import App from "./App"

checkEnvVars(["VITE_API_ENDPOINT", "VITE_SURAASA_PLATFORM_URL"])

if (import.meta.env.VITE_MOENGAGE_APP_ID) {
  MoEngage.init(import.meta.env.VITE_MOENGAGE_APP_ID, {
    debugLogs: import.meta.env.MODE === "development" ? 1 : 0,
    cluster: "DC_3",
    swPath: "/moEngage-sw.js",
  })
}

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: version,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

const container = document.getElementById("root")
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <Sentry.ErrorBoundary
    fallback={
      <SentryErrorPage error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <App />
  </Sentry.ErrorBoundary>
)
