import { Typography } from "@suraasa/placebo-ui"
import logo from "assets/logos/ito-full.png"
import bg from "assets/qr_code/qr-bg.png"
import React from "react"
import { createUseStyles } from "react-jss"

type Props = {
  iconSrc?: string
  text: string
  children: any
}

const ICON_SIZE = 100

// This is for debugging purpose. This component is made visible by html2canvas when user clicks on download
const VISIBLE = false

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundImage: `url(${bg})`,
    backgroundPosition: "center",
    display: VISIBLE ? "block" : "none",
    backgroundColor: "white",
    width: 1060,
    minHeight: 1521,
    borderRadius: "32px",
    border: "5px solid #EFEFF7",
    padding: theme.spacing(11),
    color: "black",
  },
  logo: {},
  icon: {
    width: ICON_SIZE,
    height: ICON_SIZE,
    position: "absolute",
    // Need to offset the values due to `excavate: true` in qrcode.react
    top: `calc(50% - ${ICON_SIZE / 2 + 1}px)`,
    // Need to offset the values due to `excavate: true` in qrcode.react
    left: `calc(50% - ${ICON_SIZE / 2 - 5}px)`,
  },
  qrContainer: {
    position: "relative",
    backgroundColor: "#EFEFF7",
    borderRadius: "56px",
    margin: theme.spacing(8, 0),
    padding: theme.spacing(4),
  },
  qrInner: {
    backgroundColor: "white",
    borderRadius: "32px",
    padding: 90,
  },
  partnerHeading: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "29px",
    letterSpacing: "0.1em",
  },
}))

const QRCodeTemplate = ({ iconSrc, text, children }: Props) => {
  const classes = useStyles()
  return (
    <div className={classes.root} id="partner-qr-code">
      <img alt="ito-logo" className={classes.logo} src={logo} />
      <Typography className="mt-7" variant="title1">
        To register, scan the QR Code.
      </Typography>

      <div className={classes.qrContainer}>
        {iconSrc && (
          <img
            alt="icon"
            className={classes.icon}
            height={ICON_SIZE}
            src={iconSrc}
            width={ICON_SIZE}
          />
        )}
        <div className={classes.qrInner}>{children}</div>
      </div>
      <Typography className={classes.partnerHeading} variant="emphasised">
        PARTNER
      </Typography>
      <Typography variant="largeTitle">{text}</Typography>
    </div>
  )
}

export default QRCodeTemplate
