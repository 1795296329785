import { Container, Typography } from "@suraasa/placebo-ui"
import React from "react"
import SchoolTable from "views/Partner/Dashboard/ParticipantsTable/SchoolTable"

import Onboarding from "../Onboarding"

const SchoolDashboard = () => {
  return (
    <div className="bg-white grow">
      <Container>
        <Onboarding />
        <Typography variant="title2" className="my-2">
          School
        </Typography>
        <SchoolTable search="" />
      </Container>
    </div>
  )
}

export default SchoolDashboard
