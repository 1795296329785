import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNormaURL = getServiceURL(SERVICE_PREFIX.norma)

export const urls = {
  dashboard: {
    listSchools: () => getNormaURL("/v1/olympiad-schools/partners/"),

    addSchool: () => getNormaURL("/v1/partners/schools/representatives/"),
    addTeamMember: () => getNormaURL("/v1/partners/representatives/"),
    editTeamMember: (slug: string) =>
      getNormaURL(`/v1/partners/representatives/${slug}/`),
    // teamList: () => getNormaURL("/v1/olympiads/partners/representatives/"),
    teamList: () => "/v1/olympiads/partners/representatives/",
  },
}
