import { Divider, IconButton, Typography } from "@suraasa/placebo-ui"
import DownloadDivImg from "assets/onboarding/download-template.png"
import TeacherData from "assets/onboarding/teacher-data.png"
import UploadData from "assets/onboarding/upload-data.png"
import clsx from "clsx"
import SlidingSheet from "components/SlidingSheet"
import { Cancel } from "iconoir-react"
import { PropsWithChildren, useState } from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
const { schoolPartnerOnboardingCompleted } = BROWSER_STORAGE_KEYS

const Card: React.FC<
  PropsWithChildren & { title: string; subTitle: string; className?: string }
> = ({ children, title, subTitle, className }) => {
  return (
    <div
      className={clsx(
        className,
        "flex md:flex-row flex-col md:items-center items-start justify-between p-3 bg-surface-100 rounded-2xl gap-2"
      )}
    >
      <div>
        <Typography variant="title2" color="onSurface.800" className="mb-2">
          {title}
        </Typography>
        <Typography variant="largeBody" color="onSurface.800" className="mb-2">
          {subTitle}
        </Typography>
      </div>
      <div>{children}</div>
    </div>
  )
}

const Onboarding = () => {
  const [open, setOpen] = useState(
    localStorage.getItem(schoolPartnerOnboardingCompleted) === "true"
      ? false
      : true
  )

  const closeOnboarding = () => {
    localStorage.setItem(schoolPartnerOnboardingCompleted, "true")
    setOpen(false)
  }

  return (
    <SlidingSheet
      containerClassName="grid grid-cols-12"
      className="w-full col-span-12 col-start-1 !overflow-hidden rounded-t-none md:col-start-3 md:col-span-8 sm:col-start-2 sm:col-span-10"
      open={open}
      onClose={closeOnboarding}
    >
      <div className="flex items-center px-1 py-2.5">
        <IconButton color="black" className="mr-1" onClick={closeOnboarding}>
          <Cancel />
        </IconButton>
        <Typography variant="title3">How to onboard your school</Typography>
      </div>
      <Divider />
      <div className="max-h-[90vh] h-full overflow-auto">
        <div className="flex flex-col gap-3 px-2 pt-5 md:px-7 sm:px-3">
          <Card
            title="Step 1"
            subTitle="Download the excel file template provided in the dashboard."
          >
            <a
              href="https://assets.suraasa.com/ito/registration_data_format.xlsx"
              download
              className="h-[138px] block"
            >
              <img
                src={DownloadDivImg}
                alt="download-div"
                className="object-contain w-full h-full"
              />
            </a>
          </Card>
          <Card
            title="Step 2"
            subTitle="Fill in the data of your teachers in the template file."
          >
            <div className="h-[138px]">
              <img
                src={TeacherData}
                alt="teacher-data"
                className="object-contain w-full h-full"
              />
            </div>
          </Card>
          <Card
            title="Step 3"
            subTitle="Download the excel file template provided in the dashboard."
            className="mb-3"
          >
            <div className="h-[138px]">
              <img
                src={UploadData}
                alt="teacher-data"
                className="object-contain w-full h-full"
              />
            </div>
          </Card>
        </div>
      </div>
    </SlidingSheet>
  )
}

export default Onboarding
