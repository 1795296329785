import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("partner", {
  schoolList: ({
    page,
    ordering,
    search,
    filters,
  }: {
    page?: number
    ordering?: string[]
    search?: string
    filters?: string[]
  }) => ({
    queryKey: ["schoolList", { page, ordering, search, filters }],
  }),
  teamList: ({
    page,
    ordering,
    search,
  }: {
    page?: number
    ordering?: string[]
    search?: string
  }) => ({
    queryKey: [
      "teamList",
      ...(page || ordering || search ? [{ page, ordering, search }] : []),
    ],
  }),
})
