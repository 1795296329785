import { Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

import TabItem from "./TabItem"
import { Tab, Tabs as TabsType } from "./useTabs"

const useStyles = createUseStyles({
  tabContainer: {
    display: "flex",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
})

type Props = {
  tabs: TabsType
  activeTab: Tab
  onChange: (newTab: string) => void
  className?: string
  color?: "primary" | "black"
}

const Tabs = ({
  tabs,
  activeTab,
  onChange,
  className,
  color = "primary",
}: Props) => {
  const classes = useStyles()

  const getTabTitle = (tab: Tab) => {
    return (
      <Typography
        variant={tab.name === activeTab.name ? "strong" : "body"}
        display="initial"
        className="flex gap-0.5 items-center"
      >
        {tab.name} {Boolean(tab.count) && <span>({tab.count})</span>}
      </Typography>
    )
  }

  return (
    <div className={clsx(classes.tabContainer, className)}>
      {tabs.map(tab => (
        <TabItem
          color={color}
          isActive={tab.name === activeTab.name}
          key={tab.name}
          onClick={() => onChange(tab.name)}
        >
          {tab.startAdornment ? (
            <div className="flex items-center gap-1">
              <>
                {tab.startAdornment}
                {getTabTitle(tab)}
              </>
            </div>
          ) : (
            getTabTitle(tab)
          )}
        </TabItem>
      ))}
    </div>
  )
}

export default Tabs
