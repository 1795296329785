import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

type CallableJSX = (...args: any) => JSX.Element | null

export type Tab<C = CallableJSX> = {
  name: string
  content: C
  count: number
  startAdornment?: React.SVGProps<SVGSVGElement>
} & Record<any, any>
export type Tabs<C = CallableJSX> = Tab<C>[]

const useTabs = <C = CallableJSX>({
  initialTab,
  tabs,
  searchParamKey = "tab",
  useSearchParams: useSearchParamsSync = true,
}: {
  initialTab?: string
  tabs: Tabs<C>
  searchParamKey?: string
  useSearchParams?: boolean
}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentTab, setCurrentTab] = useState(
    ((useSearchParamsSync && searchParams.get(searchParamKey)) || initialTab) ??
      tabs[0].name
  )

  useEffect(() => {
    if (useSearchParamsSync && currentTab) {
      setSearchParams(
        {
          ...Object.fromEntries(new URLSearchParams(window.location.search)),
          [searchParamKey]: currentTab,
        },
        { replace: true }
      )
    }
  }, [currentTab, searchParamKey, setSearchParams, useSearchParamsSync])

  useEffect(() => {
    if (!useSearchParamsSync) return
    const updatedSearchParam = searchParams.get(searchParamKey)
    if (updatedSearchParam) {
      setCurrentTab(updatedSearchParam)
    }
  }, [useSearchParamsSync, searchParamKey, searchParams])

  return [
    tabs.find(tab => tab.name === currentTab) || tabs[0],
    setCurrentTab,
  ] as const
}

export default useTabs
