import { IconButton, Pagination, Table, Typography } from "@suraasa/placebo-ui"
import { Container, Divider } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { APIError } from "api/utils"
import Fallback from "components/Fallback"
import SearchField from "components/SearchField"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { Edit } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"
import useCustomEvent from "utils/hooks/useCustomEvent"
import TeamMemberSheet, { TeamMemberForm } from "views/Partner/TeamMemberSheet"

const TeamTable = () => {
  const [search, setSearch] = useState("")
  const [sortBy, setSortBy] = useState<string[]>(["-id"])
  const [page, setPage] = React.useState(1)

  const [editData, setEditData] = useState<{
    slug: string
    data: TeamMemberForm
  } | null>(null)

  const { setListener: addEventHandler, removeListener: removeEventListener } =
    useCustomEvent("teamList")

  const resetTable = () => {
    setPage(1)
    setSortBy(["-id"])
    refetch()
  }

  useEffect(() => {
    addEventHandler(resetTable)

    return () => {
      removeEventListener(resetTable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: teamList,
    isError,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: queries.partner.teamList({ page, ordering: sortBy, search })
      .queryKey,
    queryFn: () =>
      api.partner.dashboard.teamList({
        params: {
          page,
          ...(Boolean(search) && { search }),
          ordering: sortBy,
        },
      }),

    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  if (isError || isLoading || !teamList) {
    return (
      <Fallback hasError={isError} isLoading={isLoading} className="mt-5" />
    )
  }

  return (
    <Container>
      <div className="flex justify-between">
        <div className="hidden sm:block">
          <SearchField
            placeholder="Search by Name"
            value={search}
            onSearch={setSearch}
          />
        </div>
      </div>
      <Divider className="relative" weight="light" />
      <div className="block mt-2 mb-1 sm:hidden">
        <SearchField
          placeholder="Search by Name"
          value={search}
          onSearch={setSearch}
        />
      </div>

      <TableWrapper
        content={className => (
          <Table
            className={className}
            data={teamList.data}
            loading={isLoading}
            headers={[
              {
                title: "Name",
                id: "name",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.800" variant="strongSmallBody">
                      {row.user.fullName}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Email",
                id: "email",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.800" variant="smallBody">
                      {row.user.email}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Phone Number",
                id: "phone_number",

                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.user.phoneNumber
                        ? `+${row.user.phoneNumber.code} ${row.user.phoneNumber.number}`
                        : "-"}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Registrations Completed",
                id: "registration_completed",

                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.registrationsCompleted ?? 0}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Registrations Incomplete",
                id: "registration_incomplete",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.registrationsIncomplete ?? 0}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "",
                id: "",
                columnComponent: ({ row }) => (
                  <td>
                    <IconButton
                      onClick={() => {
                        setEditData({
                          slug: row.slug,
                          data: {
                            name: row.user.fullName,
                            phoneNumber: row.user.phoneNumber
                              ? {
                                  code: row.user.phoneNumber.code
                                    ? `+${row.user.phoneNumber.code}`
                                    : undefined,
                                  number: row.user.phoneNumber.number
                                    ? String(row.user.phoneNumber.number)
                                    : "",
                                }
                              : { number: "" },
                            email: row.user.email,
                          },
                        })
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </td>
                ),
              },
            ]}
            multiSort={false}
            onSort={cols => {
              if (cols.length > 0) {
                setSortBy(cols)
              } else {
                setSortBy(["-id"])
              }
            }}
          />
        )}
      />

      {!isLoading && teamList.data.length === 0 && <NoDataCard />}

      {teamList.total > 10 && (
        <Pagination
          className="mt-5 mb-9"
          page={page}
          total={teamList.total}
          onChange={setPage}
        />
      )}
      <TeamMemberSheet
        editData={editData}
        open={Boolean(editData)}
        handleClose={() => {
          setEditData(null)
          refetch()
        }}
      />
    </Container>
  )
}

export default TeamTable
