import { Button, Theme, Tooltip, Typography } from "@suraasa/placebo-ui"
import {
  InvalidData,
  UploadedRegistrationData,
} from "api/resources/multiple-registration/types"
import clsx from "clsx"
import { WarningCircledOutline } from "iconoir-react"
import React, { useEffect, useMemo, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { pluralize } from "utils/helpers"

import Table, { Header } from "./Table"

const useStyles = createUseStyles(theme => ({
  table: {
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.onSurface[400]}`,
    borderRadius: 8,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1),
    "& thead": {
      borderBottom: `1px solid ${theme.colors.onSurface[400]}`,
      fontSize: "16px",
      height: "48px",
      color: theme.colors.onSurface[800],
      backgroundColor: theme.colors.surface[100],
    },
    "& th": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
      borderRight: `1px solid ${theme.colors.onSurface[400]}`,
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& th:last-child": {
      borderRight: 0,
    },
    "& td": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
      border: `1px solid ${theme.colors.onSurface[400]}`,
      // borderBottom: `1px solid ${theme.colors.onSurface[400]}`,
      backgroundColor: theme.colors.common.white[500],
    },
    "& td:first-child": {
      borderLeft: 0,
      paddingLeft: theme.spacing(4),
    },
    "& td:last-child": {
      borderRight: 0,
    },
    "& tr": {
      height: "48px",
    },
    "& tr:last-child": {
      "& td": { borderBottom: 0 },
    },
    "& td:focus-within ": {
      border: 0,
      outline: "2px solid #3B82F6",
      outlineOffset: "-2px",
    },
  },
  inputFieldCss: { all: "unset", width: "100%" },
  errorField: {
    backgroundColor: `${theme.colors.critical[100]} !important`,
  },
  buttonContainer: {
    textAlign: "end",
  },
  errorBannerContainer: {
    background: `${theme.colors.critical[50]}`,
    borderRadius: 8,
    border: `1px solid ${theme.colors.critical[400]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
  // successField: {
  //   backgroundColor: `${theme.colors.success[50]} !important`,
  // },
}))

const InvalidTable = ({
  className,
  data,
  onSave,
  onCancel,
}: {
  className?: string
  data: InvalidData[]
  onSave: (data: UploadedRegistrationData[]) => void
  onCancel: () => void
}) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()

  const [invalidData, setInvalidData] = useState<InvalidData[]>(data)

  const onChange = (
    key: "phoneNumber" | "email" | "fullName",
    newValue: string,
    rowNum: number
  ) => {
    setInvalidData(prevState =>
      prevState.map(i =>
        i.rowId === rowNum
          ? {
              ...i,
              rowId: i.rowId,
              [key]: newValue,
              errors: { ...i.errors },
            }
          : i
      )
    )
  }

  useEffect(() => {
    setInvalidData(data)
  }, [data])

  const popupConfirmation = () => {
    const decision = window.confirm(
      "If you leave this page you will have to reupload the data"
    )
    if (decision) {
      onCancel()
    }
  }

  const headers: Header<InvalidData>[] = useMemo(
    () => [
      {
        title: "Row Number",
        id: "rowId",
        sortable: false,
        align: undefined,
        columnComponent: ({ row, uniqueKey }) => (
          <td key={uniqueKey}>
            <Typography color="onSurface.800" variant="body">
              {row.rowId || "-"}
            </Typography>
          </td>
        ),
      },
      {
        title: "Name",
        id: "fullName",
        align: "left",

        columnComponent: ({ row, uniqueKey }) => (
          <td
            className={clsx({
              [classes.errorField]: Boolean(row.errors?.fullName?.length),
              // [classes.successField]: row.editfullName === false,
            })}
            key={uniqueKey}
          >
            {row.errors?.fullName?.length ? (
              <Tooltip
                maxWidth="250px"
                className="min-w-[250px]"
                title={row.errors?.fullName[0]}
              >
                <div className="flex gap-0.5">
                  <WarningCircledOutline color={theme.colors.critical[500]} />
                  <input
                    className={classes.inputFieldCss}
                    defaultValue={row.fullName}
                    type="text"
                    onBlur={e => {
                      onChange("fullName", e.target.value, row.rowId)
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <Typography color="onSurface.800" variant="body">
                {row.fullName}
              </Typography>
            )}
          </td>
          // <td key={uniqueKey}>
          //   <Typography color="onSurface.800" variant="body">
          //     {row.fullName}
          //   </Typography>
          // </td>
        ),
      },
      {
        title: "Email",
        id: "email",

        columnComponent: ({ row, uniqueKey }) => (
          <td
            className={clsx({
              [classes.errorField]: Boolean(row.errors?.email?.length),
              // [classes.successField]: row.editEmail === false,
            })}
            key={uniqueKey}
          >
            {row.errors?.email?.length ? (
              <Tooltip
                maxWidth="250px"
                className="min-w-[250px]"
                title={row.errors?.email[0]}
              >
                <div className="flex gap-0.5">
                  <WarningCircledOutline color={theme.colors.critical[500]} />
                  <input
                    className={classes.inputFieldCss}
                    defaultValue={row.email}
                    type="text"
                    onBlur={e => {
                      onChange("email", e.target.value, row.rowId)
                    }}
                  />
                </div>
              </Tooltip>
            ) : (
              <Typography color="onSurface.800" variant="body">
                {row.email}
              </Typography>
            )}
          </td>
        ),
      },
      {
        title: "Phone Number",
        id: "phoneNumber",
        columnComponent: ({ row, uniqueKey }) => (
          <td
            className={clsx({
              [classes.errorField]: Boolean(row.errors?.phoneNumber?.length),
              // [classes.successField]: row.editphoneNumber === false,
            })}
            key={uniqueKey}
          >
            {row.errors?.phoneNumber?.length ? (
              <Tooltip
                maxWidth="250px"
                className="min-w-[250px]"
                title={row.errors?.phoneNumber[0]}
              >
                <div className="flex gap-0.5">
                  <WarningCircledOutline color={theme.colors.critical[500]} />
                  <input
                    className={classes.inputFieldCss}
                    defaultValue={row.phoneNumber}
                    type="text"
                    onBlur={e => {
                      onChange("phoneNumber", e.target.value, row.rowId)
                    }}
                  />{" "}
                </div>
              </Tooltip>
            ) : (
              <Typography color="onSurface.800" variant="body">
                {row.phoneNumber}
              </Typography>
            )}
          </td>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <>
      <div>
        <div className={clsx("mb-3", classes.buttonContainer)}>
          <Button
            className="mr-2"
            color="critical"
            variant="outlined"
            onClick={popupConfirmation}
          >
            Cancel
          </Button>
          <Button onClick={() => onSave(invalidData)}>Save Changes</Button>
        </div>
        <div className={clsx("px-4 py-3", classes.errorBannerContainer)}>
          <div>
            <Typography variant="title2">
              The uploaded file has some errors
            </Typography>
            <Typography variant="body">
              Please fix the data, you can directly edit the data here. Click on
              any red field to edit.
            </Typography>
          </div>
        </div>
      </div>
      <div className={className}>
        <Typography variant="title3">Invalid Data</Typography>
        <Typography className="mb-2 mt-0.5" variant="body">
          Data of {pluralize("teacher", data.length)} is invalid. Please fix the
          data, you can directly edit the data here. Click on any red field to
          edit.
        </Typography>
        <Table
          className={clsx(classes.table)}
          data={invalidData}
          headers={headers}
          keyAttribute="rowId"
          multiSort={false}
        />
      </div>
    </>
  )
}

export default InvalidTable
