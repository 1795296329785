import { RegistrationStatus } from "../multiple-registration/types"

export type IncompleteRegistration = {
  id: number
  fullName: string
  email: string
  phoneNumber: string
}

export type Registration = {
  registrationStatus?: RegistrationStatus
  olympiadSchool: {
    name?: string
    slug?: string
  }
  country: {
    name?: string
    countryId?: string
  }
  state: {
    name?: string
    stateId?: string
  }
  fullName: string
  email?: string
  phoneNumber?: string
  registrationDate?: string
  registrationFeePaid?: boolean
  user: string
}

export type StateRegistration = {
  id: number
  name: string
  noOfSchools: number
  noOfTeachers: number
}

export type SchoolRegistration = {
  id: number
  name: string
  noOfTeachers: number
  address: string
  branch: string | null
  city: string
  pincode: string | null
  state: string
  country: string
}

export enum PartnerTypeEnum {
  SCHOOL_REPRESENTATIVE = "school_representative",
  INSTITUTION = "institution",
  INDEPENDENT = "independent",
  INTERNAL = "internal",
  PARTNER_REPRESENTATIVE = "partner_representative",
}
export type PartnerOverview = {
  id: number
  name: string
  referralCode: string
  noOfIncompleteRegistrations: number
  noOfTeachers: number
  noOfSchools: number
  noOfStates: number
  partnerType: PartnerTypeEnum
}
