import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getSolisURL = getServiceURL(SERVICE_PREFIX.solis)

export const urls = {
  refreshToken: () => getSolisURL("/v1/auth/refresh-token/"),
  generatePlatformToken: () => getSolisURL("/v1/auth/user-tokens/"),
  listUserActions: () => getSolisURL(`/v1/auth/actions/`),
}
