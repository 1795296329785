import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"

import CopyLink from "./CopyLink"
import DownloadQRCode from "./DownloadQRCode"
import styles from "./PartnerLink.module.css"

type Props = {
  isDark?: boolean
  referralCode?: string | null
  name?: string | null
}

const PartnerLinks = ({ isDark, referralCode, name }: Props) => {
  if (!name || !referralCode) return null

  const link = `${import.meta.env.VITE_SURAASA_PLATFORM_URL.replace(
    "https://",
    ""
  )}/ito/?referral=${referralCode}`

  return (
    <div
      className={clsx("py-1.5", {
        "bg-midnight text-surface-500": isDark,
        "bg-iris-500 text-surface-500": !isDark,
      })}
    >
      <Container>
        <div className="flex flex-wrap items-center justify-between gap-1">
          <Typography>{link}</Typography>
          <div className="flex items-center gap-1.5 flex-wrap">
            <CopyLink color="white" link={link} />
            <div className={clsx(styles.verticalDivider, "hidden sm:block")} />
            <DownloadQRCode color="white" name={name} link={link} />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default PartnerLinks
