import { Button } from "@suraasa/placebo-ui"
import { GlobalContext } from "components/GlobalState"
import PartnerLinks from "components/PartnerLinks"
import Tabs from "components/shared/tabs/Tabs"
import useTabs, { Tabs as TabsType } from "components/shared/tabs/useTabs"
import TitleBar from "components/TitleBar"
import { Plus } from "iconoir-react"
import React, { useContext, useState } from "react"

import AddSchoolSheet from "../AddSchoolSheet"
import TeamMemberSheet from "../TeamMemberSheet"
import ParticipantsTable from "./ParticipantsTable"
import TeamTable from "./TeamTable"

enum PartnerTabs {
  participants = "Participants",
  team = "Team",
}

const initialTabs: TabsType = [
  {
    name: PartnerTabs.team,
    content: () => null,
    count: 0,
  },
  {
    name: PartnerTabs.participants,
    content: () => null,
    count: 0,
  },
]

const PartnerDashboard = () => {
  const [openSheet, setOpenSheet] = useState(false)

  const [activeTab, setActiveTab] = useTabs({
    tabs: initialTabs,
    initialTab: initialTabs[0].name,
  })
  const { partner } = useContext(GlobalContext)

  return (
    <div className="mb-6">
      <PartnerLinks
        isDark
        name={partner?.name}
        referralCode={partner?.referralCode}
      />
      <AddSchoolSheet
        open={activeTab.name === PartnerTabs.participants && openSheet}
        handleClose={() => setOpenSheet(false)}
      />
      <TeamMemberSheet
        open={activeTab.name === PartnerTabs.team && openSheet}
        handleClose={() => setOpenSheet(false)}
      />
      <TitleBar
        action={
          <Button
            size="sm"
            startAdornment={<Plus />}
            onClick={() => {
              setOpenSheet(true)
            }}
          >
            {activeTab.name === PartnerTabs.team
              ? "Add Team Member"
              : "Add School"}
          </Button>
        }
        bottomSlot={
          <div className="mt-2">
            <Tabs
              color="black"
              activeTab={activeTab}
              tabs={initialTabs}
              onChange={setActiveTab}
            />
          </div>
        }
      />

      {activeTab.name === PartnerTabs.participants && <ParticipantsTable />}
      {activeTab.name === PartnerTabs.team && <TeamTable />}
    </div>
  )
}

export default PartnerDashboard
