import { Container, toast } from "@suraasa/placebo-ui"
import api from "api"
import {
  Overview,
  PaymentStatus,
  UploadedRegistrationData,
} from "api/resources/multiple-registration/types"
import { PartnerTypeEnum } from "api/resources/registrations/types"
import { APIError } from "api/utils"
import { GlobalContext } from "components/GlobalState"
import PartnerLinks from "components/PartnerLinks"
import CurrentRegisteredTable from "components/school-registration/CurrentRegisteredTable"
import RegistrationStatusBanner, {
  deletePartnerRegistration,
} from "components/school-registration/RegistrationStatusBanner"
import BackButton from "components/shared/BackButton"
import TitleBar from "components/TitleBar"
import React, { useContext, useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useLocation, useSearchParams } from "react-router-dom"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { handleErrors } from "utils/helpers"

import CsvView, { CsvViewType } from "./CsvView"
import Onboarding from "./School/Onboarding"

const useStyles = createUseStyles({
  container: { background: "white", minHeight: "100vh", height: "100%" },
})

const SchoolRegistration = () => {
  const classes = useStyles()
  const [searchParams] = useSearchParams()
  const schoolSlug = searchParams.get("schoolSlug")
  const schoolName = searchParams.get("schoolName")
  const referralCode = searchParams.get("referralCode")

  const { state } = useLocation()

  const { partner } = useContext(GlobalContext)

  const [step, setStep] = useState<"upload" | "csv">("upload")

  const [apiData, setApiData] = useState<CsvViewType>({
    invalidData: [],
    alreadyRegistered: [],
    validData: [],
  })
  const [overview, setOverview] = useState<Overview | null>(null)

  const handleSetSheetData = async (data: UploadedRegistrationData[]) => {
    const res = await api.multipleRegistration.create({
      data: [...apiData.alreadyRegistered, ...apiData.validData, ...data],
      urlParams: {
        olympiadSchoolSlug: schoolSlug || "",
      },
    })
    if (res.isSuccessful) {
      setApiData({
        invalidData: res.data.errorList.map(x => ({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          ...data.find(({ rowId }) => x.rowId === rowId)!,
          errors: x,
        })),
        alreadyRegistered: res.data.alreadyRegisteredTeachers.sort((a, b) =>
          a.rowId > b.rowId ? 1 : -1
        ),
        validData: res.data.validatedTeachers.sort((a, b) =>
          a.rowId > b.rowId ? 1 : -1
        ),
      })

      if (res.data.errorList.length) {
        const input = document.querySelector("input")
        if (input) input.focus()
      }

      setStep("csv")
      // navigate(routes.schoolRegistrationTable)
    } else {
      if (res.errors instanceof APIError) handleErrors(res.errors)
      if (res.errors.fieldErrors?.data) {
        toast.error(res.errors.fieldErrors?.data[0])
      }
      if (res.is500) {
        toast.error("Something went wrong")
      }
    }
  }

  useEffect(() => {
    const fetchOverview = async () => {
      const res = await api.multipleRegistration.overview({
        urlParams: {
          olympiadSchoolSlug: schoolSlug || "",
        },
      })
      if (res.isSuccessful) {
        setOverview(res.data)

        if (res.data.partnerRegistration === null) {
          localStorage.removeItem(BROWSER_STORAGE_KEYS.paymentInitiated)
        }
      } else {
        console.error(res.errors)
      }
    }
    fetchOverview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getSavedCSVData = async () => {
    if (!schoolSlug) return

    const res = await api.multipleRegistration.listRegisteredUsers({
      urlParams: {
        olympiadSchoolSlug: schoolSlug || "",
      },
    })
    if (res.isSuccessful) {
      setApiData({
        invalidData: [],
        alreadyRegistered: res.data.alreadyRegisteredTeachers.map((x, i) => ({
          ...x,
          rowId: i + 1,
        })),
        validData: res.data.validatedTeachers.map((x, i) => ({
          ...x,
          rowId: i + 1,
        })),
      })
      setStep("csv")
    } else {
      if (res.errors instanceof APIError) handleErrors(res.errors)
    }
  }
  const getMode = () => {
    const hasInvalid = apiData.invalidData.length > 0
    const hasAlreadyRegistered = apiData.alreadyRegistered.length > 0
    const hasNewData = apiData.validData.length > 0

    if (overview?.partnerRegistration?.paymentStatus === PaymentStatus.FAILED) {
      return "retryPayment"
    }

    if (hasInvalid) {
      return null
    }

    if (hasNewData) {
      return "pay"
    }

    if (hasAlreadyRegistered) {
      return "alreadyRegistered"
    }

    if (overview?.partnerRegistration?.totalNumberOfTeachers === 0) {
      return "fresh"
    }

    if (overview?.partnerRegistration != null) {
      return "pay"
    }

    return "fresh"
  }

  useEffect(() => {
    if (
      overview?.partnerRegistration != null &&
      Boolean(overview.partnerRegistration?.totalNumberOfTeachers)
    ) {
      getSavedCSVData()

      setStep("csv")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overview])

  if (!overview) return null

  return (
    <div className={classes.container}>
      {partner?.partnerType !== PartnerTypeEnum.SCHOOL_REPRESENTATIVE && (
        <PartnerLinks referralCode={referralCode} name={schoolName} />
      )}

      <Onboarding />

      <TitleBar name={schoolName} />
      <Container>
        {/* {loading && <LoadingOverlay />} */}

        {!state?.disableBackButton && <BackButton className="mb-2 " />}

        <RegistrationStatusBanner
          alreadyRegisteredCount={apiData.alreadyRegistered.length || 0}
          schoolSlug={schoolSlug}
          handleChanges={data => handleSetSheetData(data)}
          mode={getMode()}
          overview={overview}
          toBeRegisteredCount={apiData.validData.length || 0}
        />

        {step === "upload" && <CurrentRegisteredTable className="mt-4" />}
        {step === "csv" && (
          <CsvView
            data={apiData}
            onCancel={() => deletePartnerRegistration(schoolSlug)}
            onSave={data => {
              handleSetSheetData(data)
            }}
          />
        )}
      </Container>
    </div>
  )
}

export default SchoolRegistration
