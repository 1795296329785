import api from "api"
import { AuthData } from "api/resources/users/types"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { getAuthInfo, openSSOPopup, saveAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"
import { routes } from "utils/routes"

type Props = {
  openPopup?: boolean
}

export const getPlatformToken = async (
  code: string,
  onSuccess?: (data: AuthData) => void
) => {
  const res = await api.users.generatePlatformToken({
    data: {
      code,
      platform: Platforms.olympiadPartner,
    },
  })
  if (res.isSuccessful) {
    if (onSuccess) onSuccess(res.data)
  }
}

export const useAuth = ({ openPopup }: Props) => {
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(Boolean(getAuthInfo()))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.home)
      return
    }

    // The code here is for iframe messaging SSO.
    setLoading(true)
    const SSO_URL = import.meta.env.VITE_SSO_URL

    if (openPopup) {
      openSSOPopup()
    }

    const receiveMessage = (event: MessageEvent<{ code: string }>) => {
      if (event.origin === SSO_URL) {
        if ("code" in event.data) {
          getPlatformToken(event.data.code, data => {
            saveAuthInfo(data)
            setIsLoggedIn(true)
            setLoading(false)
            navigate(routes.home)
          })
        }
      }
    }
    if (!window !== undefined && !getAuthInfo()) {
      window.addEventListener("message", receiveMessage)
      return () => window.removeEventListener("message", receiveMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isLoggedIn, loading }
}
