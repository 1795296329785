import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"
import customTheme from "utils/customTheme"

const useStyles = createUseStyles(theme => ({
  uploadBannerContainer: { background: customTheme.mint[100], borderRadius: 8 },

  uploadButton: {
    backgroundColor: customTheme.mint[800],
    padding: "12px 33px",
    color: "#FFFFFF",
    cursor: "pointer",
    borderRadius: 4,
    display: "inline-block",
    "&:active": {
      outline: `2px solid ${theme.colors.interactive[400]} !important`,
      outlineOffset: "1px",
    },
    "&:hover": {
      backgroundColor: customTheme.mint[900],
    },
  },
  inputFile: { display: "none", margin: "10px" },
  downloadButton: {
    backgroundColor: theme.colors.common.white[500],
    borderColor: customTheme.mint[800],
    color: customTheme.mint[800],
    "&:hover": {
      backgroundColor: theme.colors.common.white[500],
      borderColor: customTheme.mint[800],
      color: customTheme.mint[800],
    },
  },

  circleDiv: {
    background: customTheme.mint[800],
    color: "white",
    width: 42,
    height: 42,
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  circleSpan: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20.8186px",
    lineHeight: "25px",
    letterSpacing: "-0.02em",
    color: "#FFFFFF",
  },
}))

const UploadBanner = ({
  readUploadFile,
}: {
  readUploadFile: (e: any) => void
}) => {
  const classes = useStyles()

  const [searchParams] = useSearchParams()
  const schoolName = searchParams.get("schoolName")

  return (
    <div
      className={clsx(
        "px-2 sm:px-4 py-2 sm:py-3 flex justify-between",
        classes.uploadBannerContainer
      )}
    >
      <div className="flex flex-col gap-3">
        <Typography variant="title2">
          Onboard {schoolName || "your school"} for the International Teachers’
          Olympiad!
        </Typography>
        <div className="flex flex-col gap-4 sm:flex-row">
          <div className="flex gap-2">
            <div className={clsx(classes.circleDiv, "hidden sm:flex")}>
              <span className={classes.circleSpan}>1</span>
            </div>
            <div>
              <Typography variant="title3">Download Template</Typography>
              <Typography>
                Download template excel file and fill in the teachers detail
              </Typography>
              <Button
                className={clsx("mt-1.5", classes.downloadButton)}
                component="a"
                href="https://assets.suraasa.com/ito/registration_data_format.xlsx"
                variant="outlined"
                download
              >
                Download
              </Button>
            </div>
          </div>
          <div className="flex gap-2">
            <div className={clsx(classes.circleDiv, "hidden sm:flex")}>
              <span className={classes.circleSpan}>2</span>
            </div>
            <div>
              <Typography variant="title3">Upload Data</Typography>
              <Typography>Upload the file with updated data</Typography>

              <label
                className={clsx("mt-1.5", classes.uploadButton)}
                htmlFor="upload"
              >
                Upload
                <input
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.csv"
                  className={classes.inputFile}
                  id="upload"
                  name="upload"
                  type="file"
                  onChange={readUploadFile}
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadBanner
