import { Button, ButtonProps } from "@suraasa/placebo-ui"
import { Copy } from "iconoir-react"
import React, { useState } from "react"
import CopyToClipboard from "react-copy-to-clipboard"
import { GA, GA_EVENTS } from "utils/googleAnalytics"

import styles from "./CopyLink.module.css"

type Props = {
  link: string
}

const CopyLink = ({
  link,
  color,
  label = "Copy Link",
  variant = "text",
}: Props & Pick<ButtonProps, "color" | "variant" | "label">) => {
  const [copied, setCopied] = useState(false)
  function copyToClipboard() {
    GA.trackEvent(GA_EVENTS.copied_referral_link)
    setCopied(true)

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }

  return (
    <CopyToClipboard text={link} onCopy={() => copyToClipboard()}>
      <Button
        className={styles.copyToClipboard}
        startAdornment={<Copy />}
        variant={variant}
        color={color}
      >
        {copied ? "Copied!" : label}
      </Button>
    </CopyToClipboard>
  )
}

export default CopyLink
