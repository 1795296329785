import global from "./resources/global"
import multipleRegistration from "./resources/multiple-registration"
import partner from "./resources/partner"
import registrations from "./resources/registrations"
import users from "./resources/users"

export default {
  global,
  users,
  registrations,
  multipleRegistration,
  partner,
}
