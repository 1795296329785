import { TextField } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Search } from "iconoir-react"
import debounce from "lodash/debounce"
import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

import styles from "./SearchField.module.css"

type Props = {
  value: string
  placeholder: string
  onSearch: (word: string) => void
}

const SearchField = ({ value, placeholder, onSearch }: Props) => {
  const [searchText, setSearchText] = useState(value)
  const [searchParams] = useSearchParams()
  const tab = searchParams.get("tab")

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(criteria => {
      onSearch(criteria)
    }, 750),
    [tab, onSearch]
  )

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    debouncedSearch(e.target.value)
  }

  useEffect(() => {
    setSearchText(value)
  }, [value])

  return (
    <TextField
      className={clsx(
        "!w-full sm:!w-[245px] !text-onSurface-500",
        styles.searchInput
      )}
      name="search"
      placeholder={placeholder}
      startIcon={<Search />}
      value={searchText}
      onChange={handleSearch}
    />
  )
}

export default SearchField
