import { toast } from "@suraasa/placebo-ui"
import { APIError } from "api/utils"
import { FieldValues, UseFormSetError } from "react-hook-form"

export function isUUIDString(str: string) {
  const UUIDv4Regex =
    /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
  return UUIDv4Regex.test(str)
}

/**
 *
 * @param errors
 * @param options
 * @returns
 */
export const handleErrors = <T extends FieldValues>(
  errors: APIError,
  options?: {
    setter?: UseFormSetError<T>
    fields?: ([backendKey: string, frontendKey: string] | [commonKey: string])[]
  }
) => {
  const {
    errors: { message, fieldErrors },
  } = errors
  if (message) {
    toast.error(message, { autoClose: 3000 })
  }

  if (options?.setter && options.fields) {
    options.fields.forEach(pair => {
      if (!fieldErrors) return

      const key = pair[0]
      const v = fieldErrors[key]

      if (pair.length === 1) {
        if (v) {
          options.setter!(key as any, {
            message: typeof v === "string" ? v : v[0],
          })
        }
      }
      if (pair.length === 2) {
        const frontendKey = pair[1]
        if (v) {
          options.setter!(frontendKey as any, {
            message: typeof v === "string" ? v : v[0],
          })
        }
      }
    })
    return
  }

  if (options?.setter && fieldErrors) {
    for (const [k, v] of Object.entries(fieldErrors)) {
      if (v)
        options.setter(k as any, { message: typeof v === "string" ? v : v[0] })
    }
    return
  }

  // if there was no message, but there were fieldErrors without a setter, we need to show them to the user
  if (!message && fieldErrors && !options?.setter) {
    const firstError = Object.entries(fieldErrors)[0]
    if (firstError) {
      const [k, v] = firstError
      toast.error({
        title: `Error on key '${k}'`,
        subtitle: typeof v === "string" ? v : v[0],
      })
    }
  }
}

export function pluralize(
  word: string,
  count: number,
  {
    endsWithVowel,
    skipCount,
    plural,
  }: {
    endsWithVowel?: boolean
    skipCount?: boolean
    plural?: string
  } = {
    endsWithVowel: false,
    skipCount: false,
    plural: "",
  }
) {
  let str = `${count} `

  if (skipCount) {
    str = ""
  }

  if (plural) {
    return `${count !== 1 ? `${str}${plural}` : `${str}${word}`}`
  }

  return `${
    count !== 1 ? `${str}${word}${endsWithVowel ? "es" : "s"}` : `${str}${word}`
  }`
}

export const saveBlobAsFile = ({
  data,
  type,
  name,
}: {
  data: any
  type: string
  name: string
}) => {
  const blob = new Blob([data], { type })
  const blobData = window.URL.createObjectURL(blob)
  const link = document.createElement("a")
  link.href = blobData
  link.download = name
  link.click()
  setTimeout(() => {
    window.URL.revokeObjectURL(blobData)
  }, 100)
}

export const getPlatformURL = (platform: "sso", url: string) => {
  switch (platform) {
    case "sso":
      return `${import.meta.env.VITE_SSO_URL}${url}`
    default:
      return url
  }
}

export function checkEnvVars(vars: string[]) {
  for (const v of vars) {
    if (!import.meta.env[v]) {
      throw new Error(`Please add ${v} to your environment variables`)
    }
  }
}
