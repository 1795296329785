import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("users", {
  refreshToken: () => ({
    queryKey: ["refreshToken"],
  }),
  generatePlatformToken: () => ({
    queryKey: ["generatePlatformToken"],
  }),
  userActionList: () => ({
    queryKey: ["userActionList"],
  }),
})
