import api from "api"
import { PartnerTypeEnum } from "api/resources/registrations/types"
import { GlobalContext } from "components/GlobalState"
import LoadingOverlay from "components/shared/LoadingOverlay"
import React, { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { routes } from "utils/routes"

const GetActions = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [loading, setLoading] = useState(true)
  const { setActions, setPartner } = useContext(GlobalContext)

  useEffect(() => {
    const listActions = async () => {
      // console.log("Getting listActions")
      const res = await api.users.userActions.list()
      if (res.isSuccessful) {
        setActions(res.data)
      }
    }

    async function getOverview() {
      // console.log("Getting getOverview")
      const res = await api.registrations.registrations.getOverview()
      if (res.isSuccessful) {
        setLoading(false)
        setPartner(res.data)

        switch (res.data.partnerType) {
          case PartnerTypeEnum.SCHOOL_REPRESENTATIVE:
            if (!pathname.startsWith(routes.school.root)) {
              return navigate(routes.school.dashboard, { replace: true })
            }
            break
          case PartnerTypeEnum.PARTNER_REPRESENTATIVE:
            if (!pathname.startsWith(routes.partnerTeamMember.root)) {
              return navigate(routes.partnerTeamMember.dashboard, {
                replace: true,
              })
            }
            break
          case PartnerTypeEnum.INSTITUTION:
          case PartnerTypeEnum.INDEPENDENT:
          case PartnerTypeEnum.INTERNAL:
            if (!pathname.startsWith(routes.partner.root)) {
              return navigate(routes.partner.dashboard, { replace: true })
            }
        }
      }
    }

    listActions().then(getOverview)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <LoadingOverlay showLogo opacity={0} />
  }

  // if (error) {
  //   return <ErrorScreen error={error} />
  // }
  return <Outlet />
}

export default GetActions
