import { Pagination, Table, Tag, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { RegistrationStatus } from "api/resources/multiple-registration/types"
import { Registration } from "api/resources/registrations/types"
import { PaginatedAPIResponse } from "api/types"
import clsx from "clsx"
import NoDataCard from "components/shared/NoDataCard"
import { Check } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { createUseStyles } from "react-jss"
import { useSearchParams } from "react-router-dom"

const useStyles = createUseStyles(theme => ({
  table: {
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.surface[200]}`,
    borderRadius: "8px",
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1.5),
    "& thead": {
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
      color: theme.colors.onSurface[700],
      fontSize: "16px",
      height: "48px",
    },
    "& td, th": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& td:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& tr": {
      height: "48px",
      borderBottom: `1px solid ${theme.colors.surface[200]}`,
    },
    "& tr:last-child": {
      borderBottom: 0,
    },
  },
  checkIcon: {
    background: theme.colors.success[500],
    color: "white",
    borderRadius: "50%",
  },
}))

const CurrentRegisteredTable = ({ className }: { className?: string }) => {
  const classes = useStyles()

  const [searchParams] = useSearchParams()
  const schoolName = searchParams.get("schoolName")
  const schoolSlug = searchParams.get("schoolSlug")

  const [registrations, setRegistrations] = useState<
    PaginatedAPIResponse<Registration[]>
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })
  const [page, setPage] = useState(1)
  const [sortBy, setSortBy] = useState<string[]>([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await api.multipleRegistration.alreadyRegistered.get({
        params: {
          page,
          ordering: sortBy,
          olympiad_school_slug: schoolSlug,
          fields: ["registration_status", "email", "phone_number"],
        },
      })
      if (!res.errors) {
        setRegistrations({
          data: res.data,
          nextPage: res.nextPage,
          previousPage: res.previousPage,
          total: res.total,
        })
      }
    }
    fetchData()
  }, [page, sortBy, schoolSlug])

  const getCurrentStatus = (status: RegistrationStatus) => {
    switch (status) {
      case "pending":
        return <Tag color="warning" label="Registration Pending" />
      case "completed":
        return <Tag color="success" label="Complete" />

      default:
        break
    }
  }

  return (
    <div className={className}>
      <Typography className="mb-1.5" variant="title3">
        Teachers participating from {schoolName || "your school"}
      </Typography>

      {registrations.data.length ? (
        <Table
          className={clsx(classes.table)}
          data={registrations.data}
          headers={[
            {
              title: "Name",
              id: "full_name",
              align: "left",
              sortable: true,
              columnComponent: ({ row }) => (
                <td>
                  <Typography color="onSurface.800" variant="body">
                    {row.fullName}
                  </Typography>
                </td>
              ),
            },
            {
              title: "Email",
              id: "email",
              sortable: true,
              columnComponent: ({ row }) => (
                <td>
                  <Typography color="onSurface.800" variant="body">
                    {row.email ?? "-"}
                  </Typography>
                </td>
              ),
            },
            {
              title: "Phone Number",
              id: "phoneNumber",
              sortable: false,
              columnComponent: ({ row }) => (
                <td>
                  <Typography color="onSurface.800" variant="body">
                    {row.phoneNumber ?? "-"}
                  </Typography>
                </td>
              ),
            },
            {
              title: "Registration Status",
              id: "status",
              sortable: false,
              columnComponent: ({ row }) => (
                <td>
                  {row.registrationStatus
                    ? getCurrentStatus(row.registrationStatus)
                    : "-"}
                </td>
              ),
            },
            {
              title: "Payment",
              id: "paymentStatus",
              // eslint-disable-next-line react/display-name
              columnComponent: () => (
                <td>
                  <Check className={classes.checkIcon} />
                </td>
              ),
            },
          ]}
          multiSort={false}
          onSort={cols => setSortBy(cols)}
        />
      ) : (
        <NoDataCard />
      )}

      {registrations.total > 10 && (
        <Pagination
          className="mb-9"
          page={page}
          total={registrations.total}
          onChange={setPage}
        />
      )}
    </div>
  )
}

export default CurrentRegisteredTable
