import { toast } from "@suraasa/placebo-ui"
import api from "api"
import {
  Overview,
  UploadedRegistrationData,
} from "api/resources/multiple-registration/types"
import React from "react"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import * as xlsx from "xlsx"

import AlreadyRegisteredBanner from "./banners/AlreadyRegisteredBanner"
import Payment from "./banners/Payment"
import UploadBanner from "./banners/UploadBanner"

export const deletePartnerRegistration = async (
  slug: string | null | undefined
) => {
  if (!slug) return
  const res = await api.multipleRegistration.deletePartnerRegistration({
    urlParams: {
      olympiadSchoolSlug: slug,
    },
  })
  if (res.isSuccessful) {
    localStorage.removeItem(BROWSER_STORAGE_KEYS.paymentInitiated)
    window.location.reload()
  } else {
    console.error(res.errors)
  }
}

const RegistrationStatusBanner = ({
  handleChanges,
  mode,
  overview,
  alreadyRegisteredCount,
  toBeRegisteredCount,
  schoolSlug,
}: {
  handleChanges: (data: UploadedRegistrationData[]) => void
  mode?: "pay" | "fresh" | "retryPayment" | "alreadyRegistered" | null
  overview: Overview
  schoolSlug: string | null
  alreadyRegisteredCount: number
  toBeRegisteredCount: number
}) => {
  const readUploadFile = (e: any) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = el => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const data = el.target.result
        const workbook = xlsx.read(data, { type: "array" })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = xlsx.utils.sheet_to_json(worksheet)

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return

        try {
          const formatted = json.map((x: any) => {
            const phoneNumber = x["Phone Number"].toString()

            return {
              fullName: x["Full Name"],
              email: x["Email ID"],
              phoneNumber: phoneNumber.startsWith("+")
                ? phoneNumber
                : `+${phoneNumber}`,
              rowId: x.__rowNum__,
            } as UploadedRegistrationData
          })

          if (formatted.length > 0) {
            handleChanges(formatted)
          } else {
            // eslint-disable-next-line no-throw-literal, @typescript-eslint/no-throw-literal
            throw {
              name: "EmptyFile",
              message: "The file is empty. Please upload a different file.",
            }
          }
        } catch (err: any) {
          if (err.name === "TypeError") {
            console.error("Invalid CSV format")
            toast.error("Invalid CSV format")
          }
          if (err.name === "EmptyFile") {
            console.error(err.message)
            toast.error(err.message)
          }
        }
      }
      if (e.target.files.length > 0) reader.readAsArrayBuffer(e.target.files[0])
    }

    e.target.value = ""
  }

  if (mode === "pay")
    return (
      <Payment
        alreadyRegisteredCount={alreadyRegisteredCount}
        data={overview}
        schoolSlug={schoolSlug}
        toBeRegisteredCount={toBeRegisteredCount}
        onCancel={() => deletePartnerRegistration(schoolSlug)}
      />
    )
  if (mode === "alreadyRegistered")
    return (
      <AlreadyRegisteredBanner
        onCancel={() => deletePartnerRegistration(schoolSlug)}
      />
    )

  if (!mode) return null
  if (mode === "retryPayment")
    return (
      <Payment
        alreadyRegisteredCount={alreadyRegisteredCount}
        data={overview}
        schoolSlug={schoolSlug}
        toBeRegisteredCount={toBeRegisteredCount}
        retryPayment
        onCancel={() => deletePartnerRegistration(schoolSlug)}
      />
    )

  if (mode === "fresh") {
    return <UploadBanner readUploadFile={readUploadFile} />
  }

  return null
}

export default RegistrationStatusBanner
