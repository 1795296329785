export const AUTHORIZATION_HEADER_PREFIX = "Bearer"

export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
  paymentInitiated: "paymentInitiated",
  schoolPartnerOnboardingCompleted: "schoolPartnerOnboardingCompleted",
}

export enum SERVICE_PREFIX {
  solis = "solis",
  gravity = "gravity",
  nebula = "nebula",
  kepler = "kepler",
  doppler = "doppler",
  analytics = "analytics",
  norma = "norma",
}

export enum Platforms {
  olympiadPartner = "Olympiad Partner",
}
