import { Navigate, Outlet } from "react-router-dom"
import { getAuthInfo } from "utils/auth"

import Navbar from "./Navbar/Navbar"

function PrivateRoute() {
  const auth = getAuthInfo()

  return auth ? (
    <>
      <Navbar gutterBottom={false} />
      <div id="mainContent">
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to="/" />
  )
}

export default PrivateRoute
