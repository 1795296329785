export const routes = {
  home: "/dashboard",

  // TODO: Remove this route
  help: "/help",
  login: "/",
  error: "/E88iUJijnM",

  school: {
    root: "/school",
    dashboard: "/school/dashboard",
    schoolRegistration: "/school/school-registration",
  },

  partner: {
    root: "/partner",
    dashboard: "/partner/dashboard",
    schoolRegistration: "/partner/school-registration",
  },

  partnerTeamMember: {
    root: "/partner-team-member",
    dashboard: "/partner-team-member/dashboard",
    schoolRegistration: "/partner-team-member/school-registration",
  },
} as const
