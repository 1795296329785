import { PartnerOverview } from "api/resources/registrations/types"
import { Action, User } from "api/resources/users/types"
import React, { createContext, useState } from "react"
import { Outlet } from "react-router-dom"

type GlobalUserState = Pick<User, "photo" | "firstName" | "lastName">

type Context = {
  user: GlobalUserState | null
  setUser: (user: Partial<GlobalUserState>) => void

  actions: Action[]
  setActions: (actions: Action[]) => void

  partner: PartnerOverview | null
  setPartner: (type: PartnerOverview) => void
}

export const GlobalContext = createContext<Context>({
  user: null,
  setUser: () => {},

  actions: [],
  setActions: () => {},

  partner: null,
  setPartner: () => {},
})

const GlobalState = () => {
  const [actions, setActions] = useState<Action[]>([])
  const [user, setUser] = useState<Context["user"]>(null)
  const [partner, setPartner] = useState<Context["partner"]>(null)

  return (
    <GlobalContext.Provider
      value={{
        actions,
        setActions,
        user,
        partner,
        setPartner,
        setUser: newState => {
          setUser(prevState => {
            if (!prevState) return newState as GlobalUserState
            return { ...prevState, ...newState }
          })
        },
      }}
    >
      <Outlet />
    </GlobalContext.Provider>
  )
}

export default GlobalState
