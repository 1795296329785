import { Button, ButtonProps } from "@suraasa/placebo-ui"
import ShareRegistrationLink from "components/dashboard/ShareRegistrationLink"
import html2canvas from "html2canvas"
import { QrCode } from "iconoir-react"
import React from "react"
import { GA, GA_EVENTS } from "utils/googleAnalytics"

type Props = {
  link: string
  name: string
} & Pick<ButtonProps, "color">

const DownloadQRCode = ({ color, link, name }: Props) => {
  const downloadCanvas = (canvas: HTMLCanvasElement) => {
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream")
    const downloadLink = document.createElement("a")
    downloadLink.href = pngUrl
    downloadLink.download = `${name}'s referral QR code.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  const processQR = () => {
    GA.trackEvent(GA_EVENTS.downloaded_referral_qr_code)
    const qr = document.getElementById("partner-qr-code")
    if (!qr) return

    html2canvas(qr, {
      onclone: snapshot => {
        const qrElement = snapshot.getElementById("partner-qr-code")
        if (!qrElement) return
        // Make element visible for cloning
        qrElement.style.display = "block"
      },
    }).then(canvas => {
      downloadCanvas(canvas)
    })
  }

  return (
    <>
      <ShareRegistrationLink name={name} link={link} />
      <Button
        startAdornment={<QrCode />}
        color={color}
        variant="text"
        onClick={processQR}
      >
        Download QR Code
      </Button>
    </>
  )
}

export default DownloadQRCode
