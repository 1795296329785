import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNormaURL = getServiceURL(SERVICE_PREFIX.norma)

export const urls = validateUrls({
  registrations: {
    list: () => getNormaURL("/v1/partners/registered-users/"),
    downloadCSV: () => getNormaURL("/v1/registrations/"),
    stateAnalytics: () => getNormaURL("/v1/extras/state-analytics/"),
    schools: () => getNormaURL("/v1/olympiad-schools/analytics/"),
    getOverview: () => getNormaURL("/v1/partners/"),
  },
})
