import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import { City, Country, Currency, Language, State, Subject } from "./types"
import { urls } from "./urls"

export default {
  detectCountry: async (): Promise<APIResponse<Country>> => {
    try {
      const res = await axios.get(urls.countries.current())
      return formatSuccessResponse(res)
    } catch (e) {
      const errorResponse = formatErrorResponse(e)
      return errorResponse
    }
  },
  listCountries: async ({
    params,
  }: Config): Promise<APIResponse<Country[]>> => {
    try {
      const res = await axios.get(urls.countries.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listStates: async ({
    params,
    urlParams: { countryId },
  }: ConfigWithURLParams): Promise<APIResponseOld<State[]>> => {
    try {
      const res = await axios.get(urls.states.list(countryId), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  listCities: async ({
    params,
    urlParams: { stateId },
  }: ConfigWithURLParams): Promise<APIResponseOld<City[]>> => {
    try {
      const res = await axios.get(urls.cities.list(stateId), {
        params,
      })
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  listLanguages: async ({
    params,
  }: Config): Promise<APIResponse<Language[]>> => {
    try {
      const res = await axios.get(urls.languages.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listSubjects: async ({ params }: Config): Promise<APIResponse<Subject[]>> => {
    try {
      const res = await axios.get(urls.subjects.list(), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listCurrencies: async ({
    params,
  }: Config): Promise<APIResponse<Currency[]>> => {
    try {
      const res = await axios.get(urls.currencies.list(), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
