const useCustomEvent = (event: string) => {
  const setListener = (listener: () => void) => {
    document.addEventListener(event, listener)
  }
  const dispatch = (options?: { delay?: number }) => {
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent(event))
    }, options?.delay ?? 0)
  }

  const removeListener = (listener: () => void) => {
    document.removeEventListener(event, listener)
  }

  return {
    setListener,
    dispatch,
    removeListener,
  }
}

export default useCustomEvent
