import { Button } from "@suraasa/placebo-ui"
import { GlobalContext } from "components/GlobalState"
import PartnerLinks from "components/PartnerLinks"
import TitleBar from "components/TitleBar"
import { Plus } from "iconoir-react"
import React, { useContext, useState } from "react"
import AddSchoolSheet from "views/Partner/AddSchoolSheet"
import ParticipantsTable from "views/Partner/Dashboard/ParticipantsTable"

const PartnerTeamMemberDashboard = () => {
  const [openSheet, setOpenSheet] = useState(false)

  const { partner } = useContext(GlobalContext)

  return (
    <div>
      <PartnerLinks
        isDark
        name={partner?.name}
        referralCode={partner?.referralCode}
      />

      <AddSchoolSheet
        open={openSheet}
        handleClose={() => setOpenSheet(false)}
      />
      <TitleBar
        action={
          <Button
            size="sm"
            startAdornment={<Plus />}
            onClick={() => {
              setOpenSheet(true)
            }}
          >
            Add School
          </Button>
        }
      />

      <ParticipantsTable />
    </div>
  )
}

export default PartnerTeamMemberDashboard
