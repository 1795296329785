import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"

type Props = {
  href?: string
}

const useStyles = createUseStyles(theme => ({
  logo: {
    textDecorationLine: "none",
  },
  name: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))

const NavLogo = ({ href = "#" }: Props) => {
  const classes = useStyles()

  return (
    <a className={clsx("flex gap-2 items-center", classes.logo)} href={href}>
      <img
        className="hidden md:block"
        alt="ito-partner"
        height="28"
        src="https://assets.suraasa.com/ito/logos/ito-partner-one-line.svg"
      />
      <img
        className="hidden sm:block md:hidden"
        alt="ito-partner"
        width="200"
        src="https://assets.suraasa.com/ito/logos/ito-partner-two-line.svg"
      />
      <img
        className="sm:hidden"
        alt="ito-partner"
        height="20"
        width="40"
        src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
      />
    </a>
  )
}

export default NavLogo
