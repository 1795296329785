import {
  Button,
  SplitTextField,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import SlidingSheet from "components/SlidingSheet"
import { Cancel } from "iconoir-react"
import React, { useEffect, useMemo } from "react"
import { Controller, useForm } from "react-hook-form"
import countryCodes from "utils/countryCodes"
import { handleErrors } from "utils/helpers"
import useCustomEvent from "utils/hooks/useCustomEvent"
import useDetectCountry from "utils/hooks/useDetectCountry"

import styles from "./TeamMemberSheet.module.css"

type Props = {
  open: boolean
  handleClose: () => void
  editData?: null | { slug: string; data: TeamMemberForm }
}

export type TeamMemberForm = {
  email: string
  name: string
  phoneNumber: {
    code?: string
    number?: string
  }
}

const getInitialValues = (): TeamMemberForm => ({
  email: "",
  name: "",
  phoneNumber: { code: undefined, number: "" },
})

const TeamMemberSheet = ({ open, handleClose, editData }: Props) => {
  const { dispatch: callEventListener } = useCustomEvent("teamList")

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    setError,
    control,
    setValue,
    formState: { errors },
  } = useForm<TeamMemberForm>({ defaultValues: getInitialValues() })

  const options = useMemo(
    () =>
      countryCodes.map(({ dialCode }) => ({
        value: dialCode,
        label: dialCode,
      })),
    []
  )

  const onClose = () => {
    reset(getInitialValues())
    handleClose()
  }

  useEffect(() => {
    if (editData) {
      reset(editData.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData])

  const { mutate: editTeamMembers } = useMutation({
    mutationFn: (formData: TeamMemberForm) => {
      return api.partner.dashboard.editTeamMembers({
        params: {
          slug: editData?.slug,
        },
        data: {
          ...formData,
          phoneNumber: `${formData.phoneNumber.code}${formData.phoneNumber.number}`,
        },
      })
    },
    onSuccess: () => {
      toast.success("Team member edited successfully")
      callEventListener({ delay: 500 })
      onClose()
    },
    onError: err => {
      if (err instanceof APIError) {
        console.log(errors)
        handleErrors(err, {
          setter: setError,
          fields: [["user", "email"], ["name"], ["email"], ["phoneNumber"]],
        })
      }
    },
  })

  const { mutate: addTeamMember } = useMutation({
    mutationFn: (formData: TeamMemberForm) => {
      return api.partner.dashboard.addTeamMember({
        data: {
          ...formData,
          phoneNumber: `${formData.phoneNumber.code}${formData.phoneNumber.number}`,
        },
      })
    },
    onSuccess: () => {
      toast.success("Team member added successfully")
      callEventListener({ delay: 500 })
      onClose()
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err, {
          setter: setError,
          fields: [["user", "email"], ["name"], ["email"], ["phoneNumber"]],
        })
      }
    },
  })

  const onSubmit = handleSubmit(data => {
    clearErrors()
    if (editData) {
      editTeamMembers(data)
    } else {
      addTeamMember(data)
    }
  })

  const currentCountry = useDetectCountry()

  useEffect(() => {
    const code = countryCodes.find(
      i => i.code === currentCountry?.isoCode
    )?.dialCode

    if (code) setValue("phoneNumber.code", code)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCountry, open])

  return (
    <SlidingSheet
      from="end"
      open={open}
      onClose={onClose}
      className="px-3 pt-4 pb-3 h-screen flex flex-col sm:max-w-[420px] w-full"
    >
      <Button
        variant="text"
        color="black"
        onClick={onClose}
        startAdornment={<Cancel />}
        className="-ml-1 mb-2.5"
      >
        Close
      </Button>
      <Typography variant="title3" className="mb-3">
        {editData ? "Edit" : "Add"} Team Member
      </Typography>

      <form
        onSubmit={onSubmit}
        className="flex flex-col justify-between h-full"
      >
        <div className="flex flex-col gap-3">
          <TextField
            label="Name"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            placeholder="Ex. John Doe"
            fullWidth
            {...register("name", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="Email ID"
            type="email"
            inputLabelProps={{ required: true }}
            error={Boolean(errors.email)}
            helperText={errors.email?.message}
            placeholder="Ex. someone@example.edu"
            fullWidth
            {...register("email", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field }) => (
              <SplitTextField
                className={styles.phoneNumber}
                inputLabelProps={{ required: true }}
                label="Phone Number"
                error={Boolean(errors.phoneNumber)}
                helperText={errors.phoneNumber?.message}
                selectProps={{
                  value: { value: field.value.code, label: field.value.code },
                  options,
                  onChange: val => {
                    clearErrors()
                    if (val) setValue("phoneNumber.code", val.value)
                  },
                }}
                textFieldProps={{
                  type: "number",
                  placeholder: "Enter phone number",
                  value: field.value.number ?? undefined,
                  onChange: val => {
                    clearErrors()
                    setValue("phoneNumber.number", val.target.value)
                  },
                }}
                fullWidth
              />
            )}
            rules={{
              validate: v => {
                if (!v) return "Required"
                if (!v.code) return "Required"
                if (!v.number) return "Required"
              },
              required: {
                value: true,
                message: "Required",
              },
            }}
          />
        </div>
        <Button type="submit" className="!rounded-full" fullWidth>
          {editData ? "Edit" : "Add"}
        </Button>
      </form>
    </SlidingSheet>
  )
}

export default TeamMemberSheet
