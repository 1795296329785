import { Currency } from "../global/types"

export type RegistrationStatus = "pending" | "completed"

export enum PaymentStatus {
  NOT_INITIATED = 1,
  INITIATED = 2,
  COMPLETED = 3,
  FAILED = 4,
}

export type RegistrationDetails = {
  id: number
  fullName: string
  email: string
  phoneNumber: string
  status: RegistrationStatus
}

export type UploadedRegistrationData = {
  rowId: number
  fullName: string
  email: string
  phoneNumber: string
}

type Error = {
  email?: string[]
  phoneNumber?: string[]
  fullName?: string[]
}

export type InvalidData = {
  errors?: Error
} & UploadedRegistrationData

export type ValidateResponse = {
  id: number
  errorList: ({
    rowId: number
  } & Error)[]
  alreadyRegisteredTeachers: UploadedRegistrationData[]
  validatedTeachers: UploadedRegistrationData[]
}
export type Overview = {
  partnerRegistration: {
    id: number
    paymentStatus: PaymentStatus
    totalAmount: number | null
    paymentLink: string | null
    totalNumberOfTeachers?: number
  } | null
  currency: Currency
  pricePerTeacher: number
}

export type PaymentDetails = {
  totalAmount: number
  unregisteredUsersCount: number
  paymentLink: string
  currency: {
    symbol: string
  }
}
