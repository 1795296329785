import QRCodeTemplate from "components/dashboard/QRCodeTemplate"
import { QRCodeSVG } from "qrcode.react"
import React from "react"

type Props = {
  name: string
  link: string
}
const ShareRegistrationLink = ({ name, link }: Props) => {
  return (
    <QRCodeTemplate
      iconSrc="/logos/olympiad-logo-white-100x100.svg"
      text={name}
    >
      <QRCodeSVG
        // Passing image here doesn't render when we convert html to canvas. So, we handle it manually.
        // We are only using this prop for `excavate: true` use case.
        imageSettings={{
          src: "",
          x: undefined,
          y: undefined,
          height: 80,
          width: 80,
          excavate: true,
        }}
        level="H"
        size={640}
        value={link}
      />
    </QRCodeTemplate>
  )
}

export default ShareRegistrationLink
