import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { ArrowUp } from "iconoir-react"
import capitalize from "lodash/capitalize"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { createUseStyles } from "react-jss"

const useStyles = createUseStyles(
  theme => ({
    root: {
      width: "100%",
      overflowX: "auto",
      border: "1px solid #999",
      borderRadius: "4px",
      background: theme.colors.surface[500],
    },
    /**
     * Styles applied to the root table element.
     */
    table: {
      minWidth: "850px",
      width: "100%",
      borderCollapse: "collapse",
      overflow: "hidden",
      "& thead": {
        borderBottom: "1px solid #999",
      },
      "& tbody": {
        position: "relative",
      },
      "& th, td": {
        padding: theme.spacing(1),
      },
    },
    /**
     * Styles applied to the table header content.
     */
    thContent: {
      display: "flex",
      alignItems: "center",
      gap: theme.spacing(1),
    },
    /**
     * Styles applied to the sorting arrow.
     */
    arrowIcon: {
      borderRadius: "4px",
      color: theme.colors.onSurface[400],
      "&:hover, &:active": {
        background: theme.colors.surface[200],
      },
      "&:focus-visible, &:active": {
        outline: `2px solid ${theme.colors.interactive[400]}`,
      },
      "&, & svg": {
        width: "18px",
        height: "18px",
      },
      transition: "transform 0.2s ease-in-out, color 0.2s ease-in-out",
    },
    /**
     * Styles applied to the sorting arrow when sorting.
     */
    arrowActive: {
      color: theme.colors.onSurface[800],
    },
    /**
     * Styles applied to the sorting arrow when sorting in opposite order.
     */
    arrowFlipped: {
      transform: "rotate(180deg)",
    },
    /**
     * Styles applied when align="left".
     */
    alignLeft: {
      textAlign: "left",
      justifyContent: "flex-start",
    },
    /**
     * Styles applied when align="center".
     */
    alignCenter: {
      textAlign: "center",
      justifyContent: "center",
    },
    /**
     * Styles applied when align="right".
     */
    alignRight: {
      textAlign: "right",
      justifyContent: "flex-end",
    },
    /**
     * Styles applied to the circular progress overlay when loading=true
     */
    loadingOverlay: {
      position: "absolute",
      height: "100%",
      width: "100%",
      background: `${theme.colors.surface[100]}BF`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    /**
     * Styles applied to the dummy row when there is no content and loading=true.
     */
    overlayDummy: {
      height: "100px",
    },
  }),
  { name: "Table" }
)

export type Header<T extends Record<string, any>> = {
  /**
   * The title of the column.
   */
  title?: string
  /**
   * The id of the column.
   */
  id: keyof T | string
  /**
   * Marks the column as sortable.
   */
  sortable?: boolean
  /**
   * The alignment of the column.
   */
  align?: "left" | "right" | "center"
  /**
   * The colspan of the column.
   */
  colspan?: number
  /**
   * The custom component to render for the column.
   */
  columnComponent?: React.ComponentType<{
    row: T
    uniqueKey: string
  }>
}

export type TableProps<T extends Record<string, any>> = {
  /**
   * The data to display in the table.
   */
  data: T[]

  keyAttribute?: keyof T
  /**
   * The headers to display in the table.
   */
  headers: Header<T>[]
  /**
   * Renders a custom component for each row.
   */
  rowComponent?: (row: T) => React.ReactNode
  /**
   * Renders a custom sort icon for each header.
   */
  sortIcon?: React.ReactNode
  /**
   * Hide the header row.
   */
  hideHeaders?: boolean
  /**
   * The function to call when a column is sorted.
   */
  onSort?: (columns: string[]) => void
  /**
   * Styles applied to the root element.
   */
  className?: string
  /**
   * Toggle for component's loading state.
   */
  loading?: boolean
  /**
   * To enable multi column sorting
   */
  multiSort?: boolean
}

const Table = <T extends Record<string, any>>(props: TableProps<T>) => {
  const classes = useStyles()
  const [sortColumns, setSortColumns] = useState<string[]>([])
  const {
    data,
    headers,
    hideHeaders,
    rowComponent,
    sortIcon,
    className,
    onSort,
    loading = false,
    multiSort = true,
    keyAttribute,
  } = props

  const initialRender = useRef(true)

  /**
   * Handles column sorting and calls the onSort prop if it exists.
   */
  const handleSort = useCallback(
    (column: string) => {
      setSortColumns(prevSortColumns => {
        if (prevSortColumns.includes(column)) {
          const filteredColumns = prevSortColumns.map(col =>
            col === column ? `-${column}` : col
          )
          return filteredColumns
        }

        if (prevSortColumns.includes(`-${column}`)) {
          const filteredColumns = prevSortColumns.filter(
            c => c !== `-${column}`
          )
          return filteredColumns
        }

        const filteredColumns = multiSort
          ? [...prevSortColumns, column]
          : [column]

        return filteredColumns
      })
    },
    [multiSort]
  )

  useEffect(() => {
    // Don't want to run this on initial render because it will always be `[]`. Otherwise, consumer would have to handle it.
    if (initialRender.current) {
      initialRender.current = false
    } else if (onSort) onSort(sortColumns)

    // Not adding onSort in deps because we can't assume that the consumer will have it wrapped in useCallback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortColumns])

  /**
   * Renders the table headers row.
   */
  const TableHeaders = useMemo(
    () =>
      headers.map(({ id, title, sortable = false, align = "left" }) => (
        <th className={clsx(`th_${id.toString()}`)} key={id.toString()}>
          <div
            className={clsx(
              classes.thContent,
              classes[`align${capitalize(align)}` as keyof typeof classes]
            )}
          >
            <Typography variant="strong">{title}</Typography>
            {
              /**
               * if the column is sortable, render the sort icon
               */
              sortable && (
                <button
                  className={clsx(classes.arrowIcon, {
                    [classes.arrowFlipped]: sortColumns.includes(
                      `-${id.toString()}`
                    ),
                    [classes.arrowActive]:
                      sortColumns.includes(id.toString()) ||
                      sortColumns.includes(`-${id.toString()}`),
                  })}
                  onClick={() => {
                    handleSort(id.toString())
                  }}
                >
                  {sortIcon || <ArrowUp />}
                </button>
              )
            }
          </div>
        </th>
      )),
    [headers, classes, handleSort, sortColumns, sortIcon]
  )

  /**
   * Renders the table data rows.
   */
  const TableRows = useMemo(
    () =>
      data.map((row, index) => {
        if (rowComponent) return rowComponent(row)

        const rowKey = keyAttribute ? row[keyAttribute] : index
        return (
          <tr data-id={rowKey} key={rowKey}>
            {headers.map(
              (
                { id, columnComponent: ColumnComponent, align = "left" },
                colIdx
              ) => {
                const columnKey = `${rowKey}-${colIdx}`
                /**
                 * if custom column component is provided then that is rendered instead of the default.
                 */
                if (ColumnComponent)
                  return (
                    <ColumnComponent
                      key={columnKey}
                      row={row}
                      uniqueKey={columnKey}
                    />
                  )
                return (
                  <td
                    className={clsx(
                      "td",
                      classes[
                        `align${capitalize(align)}` as keyof typeof classes
                      ],
                      `td_${id.toString()}`
                    )}
                    data-id={columnKey}
                    key={columnKey}
                  >
                    <Typography>{row[id]}</Typography>
                  </td>
                )
              }
            )}
          </tr>
        )
      }),
    [data, headers, classes, rowComponent, keyAttribute]
  )

  return (
    <div className={clsx(classes.root, className)}>
      <table className={clsx(classes.table, "table")}>
        {!hideHeaders && (
          <thead>
            <tr>{TableHeaders}</tr>
          </thead>
        )}
        <tbody>
          {loading && (
            <>
              <tr className={classes.loadingOverlay}>
                <td>
                  <div>
                    <CircularProgress />
                  </div>
                </td>
              </tr>
              {data.length === 0 && (
                <tr className={classes.overlayDummy}>
                  <td />
                </tr>
              )}
            </>
          )}
          {TableRows}
        </tbody>
      </table>
    </div>
  )
}

export default Table
