import axios from "api/axios"
import { APIResponseOld, Config } from "api/types"
import { formatErrorResponseOld, formatSuccessResponseOld } from "api/utils"

import { Action, AuthData, RefreshTokenResponse } from "./types"
import { urls } from "./urls"

export default {
  refreshToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponseOld<RefreshTokenResponse>> => {
    try {
      const res = await axios.post(urls.refreshToken(), data, options)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  generatePlatformToken: async ({
    data,
  }: Config): Promise<APIResponseOld<AuthData>> => {
    try {
      const res = await axios.post(urls.generatePlatformToken(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  userActions: {
    list: async (): Promise<APIResponseOld<Action[]>> => {
      try {
        const res = await axios.get(urls.listUserActions())
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },
}
