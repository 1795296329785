import { Table, Typography } from "@suraasa/placebo-ui"
import { UploadedRegistrationData } from "api/resources/multiple-registration/types"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import customTheme from "utils/customTheme"
import { pluralize } from "utils/helpers"

const useStyles = createUseStyles(theme => ({
  table: {
    background: "#FFFFFF",
    border: `1px solid ${theme.colors.onSurface[400]}`,
    borderRadius: 8,
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1),
    "& thead": {
      borderBottom: `1px solid ${theme.colors.onSurface[400]}`,
      color: theme.colors.onSurface[50],
      backgroundColor: theme.colors.onSurface[700],
      fontSize: "16px",
      height: "48px",
    },
    "& th": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
      borderRight: `1px solid ${theme.colors.onSurface[400]}`,
    },
    "& th:first-child": {
      paddingLeft: theme.spacing(4),
    },
    "& th:last-child": {
      borderRight: 0,
    },
    "& td": {
      padding: theme.spacing(1),
      paddingRight: theme.spacing(6),
      border: `1px solid ${theme.colors.onSurface[400]}`,
      // borderBottom: `1px solid ${theme.colors.onSurface[400]}`,
      backgroundColor: theme.colors.onSurface[100],
    },
    "& td:first-child": {
      borderLeft: 0,
      paddingLeft: theme.spacing(4),
    },
    "& td:last-child": {
      borderRight: 0,
    },
    "& tr": {
      height: "48px",
    },
    "& tr:last-child": {
      "& td": { borderBottom: 0 },
    },
    "& td:focus-within ": {
      border: 0,
      outline: "2px solid #3B82F6",
    },
  },
  finalTable: {
    "& thead": {
      color: theme.colors.onSurface[800],
      backgroundColor: theme.colors.success[100],
    },
    "& td": {
      backgroundColor: theme.colors.common.white[500],
    },
  },
  lightColorTable: {
    "& thead": {
      color: theme.colors.onSurface[800],
      backgroundColor: theme.colors.surface[100],
    },
    "& td": {
      backgroundColor: theme.colors.common.white[500],
    },
  },
  errorField: {
    backgroundColor: `${customTheme.salmon[100]} !important`,
  },
  successField: {
    backgroundColor: `${theme.colors.success[50]} !important`,
  },
  inputFieldCss: { all: "unset", width: "100%" },
}))

const AlreadyRegistered = ({
  className,
  data,
  totalRegisteredTeachers,
}: {
  className?: string
  data: UploadedRegistrationData[]
  totalRegisteredTeachers: number
}) => {
  const classes = useStyles()

  return (
    <div className={className}>
      <Typography variant="title3">Teachers Already Registered</Typography>
      <Typography className="mb-2 mt-0.5" variant="body">
        {pluralize("teacher", data.length)} from your school{" "}
        {pluralize("is", data.length, { skipCount: true, plural: "are" })}{" "}
        already registered for ITO, hence you don’t have to pay for their
        registration fee.{" "}
        {totalRegisteredTeachers > 0 &&
          `Now you only have to pay for ${pluralize(
            "teacher",
            totalRegisteredTeachers
          )}`}
      </Typography>
      <Table
        className={clsx(classes.table)}
        data={data}
        headers={[
          {
            title: "Row Number",
            id: "rowId",
            sortable: false,
            align: undefined,
            columnComponent: ({ row }) => (
              <td>
                <Typography color="onSurface.800" variant="body">
                  {row.rowId || "-"}
                </Typography>
              </td>
            ),
          },
          {
            title: "Name",
            id: "fullName",
            align: "left",
            sortable: false,
            columnComponent: ({ row }) => (
              <td>
                <Typography color="onSurface.800" variant="body">
                  {row.fullName}
                </Typography>
              </td>
            ),
          },
          {
            title: "Email",
            id: "email",
            sortable: false,
            columnComponent: ({ row }) => (
              <td>
                <Typography color="onSurface.800" variant="body">
                  {row.email ?? "-"}
                </Typography>
              </td>
            ),
          },
          {
            title: "Phone Number",
            id: "phoneNumber",
            sortable: false,
            columnComponent: ({ row }) => (
              <td>
                <Typography color="onSurface.800" variant="body">
                  {row.phoneNumber ?? "-"}
                </Typography>
              </td>
            ),
          },
        ]}
        multiSort={false}
      />
    </div>
  )
}

export default AlreadyRegistered
