import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React, { useEffect } from "react"
import { createUseStyles } from "react-jss"
import { useNavigate, useSearchParams } from "react-router-dom"
import { getAuthInfo, getSSOURL, saveAuthInfo } from "utils/auth"
import { GA } from "utils/googleAnalytics"
import { getPlatformToken, useAuth } from "utils/hooks/useAuth"
import { routes } from "utils/routes"

const useStyles = createUseStyles({
  root: {
    height: "100vh",
  },
})

const SSO = () => {
  const classes = useStyles()
  useAuth({ openPopup: false })

  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const code = searchParams.get("code")
    if (code) {
      getPlatformToken(code, auth => {
        saveAuthInfo(auth)
        GA.setUserId(auth.user.uuid)
        navigate(routes.home)
      })
      searchParams.delete("code")
      setSearchParams(searchParams)
      return
    }

    if (!getAuthInfo()) {
      window.location.href = getSSOURL()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div
      className={clsx(
        classes.root,
        "flex flex-col justify-center items-center"
      )}
    >
      {/* <Button onClick={openSSOPopup}>Click Here to authenticate</Button>
      <Typography className="mt-1">
        Facing some issues? <a href={getSSOURL()}>Click here</a>.
      </Typography> */}
      <CircularProgress />
      <Typography className="mt-1">You are being redirected</Typography>
    </div>
  )
}

export default SSO
