import {
  Pagination,
  Table,
  TableFilter,
  theme,
  Typography,
} from "@suraasa/placebo-ui"
import api from "api"
import { Registration } from "api/resources/registrations/types"
import { PaginatedAPIResponse } from "api/types"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { CheckCircledOutline } from "iconoir-react"
import React, { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"
// import { useDownloadCSV } from "utils/hooks/useDownloadCSV"

type Props = {
  search: string
  onCountUpdate: (count: number) => void
  isActive: boolean
}

const paymentFilters = [
  {
    displayName: "Payment Completed",
    value: "true",
  },
  {
    displayName: "Payment Pending",
    value: "false",
  },
]

const Incomplete = ({ search, onCountUpdate, isActive }: Props) => {
  // const { loading: downloading, download } = useDownloadCSV({
  //   fileName: "Incomplete Registrations.csv",
  //   apiProps: {
  //     params: {
  //       registration_fee_paid: false,
  //     },
  //   },
  // })

  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState<string[]>([])
  const [filters, setFilters] = useState<TableFilter[]>([])

  const [registrations, setRegistrations] = useState<
    PaginatedAPIResponse<Registration[]>
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  useEffect(() => {
    const fetchRegistrations = async () => {
      const feePaidFilters = filters.filter(
        ({ id }) => id === "registration_fee_paid"
      )

      const res = await api.registrations.list({
        params: {
          page,
          search,
          ordering: sortBy,
          registration_status: "pending",
          registration_fee_paid:
            feePaidFilters.length === paymentFilters.length
              ? []
              : feePaidFilters.map(({ value }) => value),
          fields: ["email", "phone_number", "registration_fee_paid"],
        },
      })

      if (!res.errors) {
        setRegistrations({
          data: res.data,
          nextPage: res.nextPage,
          previousPage: res.previousPage,
          total: res.total,
        })
      } else {
        handleErrors(res.errors)
      }

      setLoading(false)
    }
    fetchRegistrations()
  }, [page, search, sortBy, filters])

  useEffect(() => {
    setPage(1)
  }, [search])

  // useEffect(() => {
  //   setPage(1)
  // }, [isActive])

  useEffect(() => {
    onCountUpdate(registrations?.total || 0)
  }, [registrations])

  if (!isActive) return null

  return (
    <>
      <div className="flex justify-end">
        {/* <Button
          loading={downloading}
          disabled={loading || registrations.total === 0}
          startAdornment={<Download />}
          variant="text"
          onClick={download}
        >
          Download CSV
        </Button> */}
      </div>
      <TableWrapper
        content={className => (
          <Table
            className={className}
            data={registrations.data}
            loading={loading}
            headers={[
              {
                title: "Name",
                id: "full_name",
                align: "left",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography variant="strong">{row.fullName}</Typography>
                  </td>
                ),
              },
              {
                title: "Email",
                id: "email",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.email}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Phone Number",
                id: "phone_number",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.phoneNumber}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Payment",
                id: "registration_fee_paid",
                filters: paymentFilters,
                columnComponent: ({ row }) => (
                  <td>
                    {row.registrationFeePaid ? (
                      <CheckCircledOutline color={theme.colors.success[500]} />
                    ) : (
                      "-"
                    )}
                  </td>
                ),
              },
            ]}
            multiSort={false}
            onSort={cols => setSortBy(cols)}
            onFilter={filters => setFilters(filters)}
          />
        )}
      />
      {!loading && registrations.data.length === 0 && <NoDataCard />}

      {registrations.total > 10 && (
        <Pagination
          className="mt-5 mb-9"
          page={page}
          total={registrations.total}
          onChange={setPage}
        />
      )}
    </>
  )
}

export default Incomplete
