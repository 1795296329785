import {
  InvalidData,
  UploadedRegistrationData,
} from "api/resources/multiple-registration/types"
import AlreadyRegistered from "components/school-registration/AlreadyRegistered"
import FinalRegistrationTable from "components/school-registration/FinalRegistrationTable"
import InvalidTable from "components/school-registration/InvalidTable"
import React from "react"

type Props = {
  data: CsvViewType
  onSave: (data: UploadedRegistrationData[]) => void
  onCancel: () => void
}

export type CsvViewType = {
  alreadyRegistered: UploadedRegistrationData[]
  validData: UploadedRegistrationData[]
  invalidData: InvalidData[]
}

const CsvView = ({ data, onSave, onCancel }: Props) => (
  <>
    {data.invalidData.length > 0 && (
      <InvalidTable
        className="mt-4"
        data={data.invalidData}
        onCancel={onCancel}
        onSave={changeData => onSave(changeData)}
      />
    )}

    {data.alreadyRegistered.length > 0 && (
      <AlreadyRegistered
        className="mt-4"
        data={data.alreadyRegistered}
        totalRegisteredTeachers={
          data.validData.length + data.invalidData.length
        }
      />
    )}

    {data.validData.length > 0 && (
      <FinalRegistrationTable
        className="mt-4"
        data={data.validData}
        final={!data.invalidData.length}
      />
    )}
  </>
)

export default CsvView
