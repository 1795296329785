import { Button, CircularProgress, Typography } from "@suraasa/placebo-ui"
import MysteryBox from "assets/Fallback/mystery-box.svg"
import clsx from "clsx"
import React from "react"

type Props = {
  data?: {
    image?: string
    title: string
    description?: string
  }
  isLoading?: boolean
  hasError?: boolean
  children?: React.ReactNode
  className?: string
}

const Fallback = ({
  data,
  isLoading,
  hasError,
  children,
  className,
}: Props) => {
  if (isLoading) {
    return (
      <div className="flex items-center justify-center p-4">
        <CircularProgress />
      </div>
    )
  }

  const imageSrc = data?.image || MysteryBox

  if (hasError) {
    return (
      <div className={clsx(className)}>
        <img src={imageSrc} alt="svg-logo" className="mx-auto mb-3" />
        <div
          style={{
            maxWidth: 450,
          }}
          className="mx-auto"
        >
          <Typography
            textAlign="center"
            variant="title3"
            color="onSurface.800"
            className="mb-1"
          >
            It seems that there has been some issue!
            <Button
              variant="link"
              onClick={() => {
                window.location.reload()
              }}
            >
              Refresh the page
            </Button>
            or try again later.
          </Typography>
        </div>
      </div>
    )
  }
  if (data) {
    return (
      <>
        <div className={className}>
          <img src={imageSrc} alt="svg-logo" className="mx-auto mb-3" />
          <div
            style={{
              maxWidth: 450,
            }}
            className="mx-auto"
          >
            <Typography
              variant="title3"
              color="onSurface.800"
              textAlign="center"
              className="mb-1"
            >
              {data.title}
            </Typography>
            {data.description && (
              <Typography
                variant="smallBody"
                textAlign="center"
                color="onSurface.500"
              >
                {data.description}
              </Typography>
            )}
          </div>
        </div>
        {children}
      </>
    )
  }
  return null
}

export default Fallback
