import api from "api"
import { Country } from "api/resources/global/types"
import { useEffect, useState } from "react"

type Props = {
  cache?: boolean
}

const STORAGE_KEY_NAME = "currentCountry"

const useDetectCountry = ({ cache }: Props = { cache: true }) => {
  const [country, setCountry] = useState<Country | null>(null)

  useEffect(() => {
    const cachedValue = sessionStorage.getItem(STORAGE_KEY_NAME)
    if (cache && cachedValue) {
      setCountry(JSON.parse(cachedValue) as Country)
      return
    }

    const detectCountry = async () => {
      try {
        const res = await api.global.detectCountry()
        setCountry(res)
        sessionStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(res))
      } catch {
        console.warn("Unable to detect country")
      }
    }
    detectCountry()
  }, [cache])

  return country
}

export default useDetectCountry
