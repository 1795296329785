import {
  IconButton,
  Pagination,
  Table,
  TableFilter,
  Typography,
} from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { PartnerSchool } from "api/resources/partner/types"
import { PartnerTypeEnum } from "api/resources/registrations/types"
import { APIError } from "api/utils"
import Fallback from "components/Fallback"
import { GlobalContext } from "components/GlobalState"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { ArrowRight } from "iconoir-react"
import React, { useContext, useEffect, useState } from "react"
import { createSearchParams, Link, useNavigate } from "react-router-dom"
import { handleErrors } from "utils/helpers"
import useCustomEvent from "utils/hooks/useCustomEvent"
import { routes } from "utils/routes"

const getLink = (data: PartnerSchool) => {
  const params = createSearchParams({
    schoolName: data.name,
    schoolSlug: data.slug,
    referralCode: data.referralCode ?? "",
  })
  return `${routes.partner.schoolRegistration}?${params.toString()}`
}

type Props = {
  search: string
  onCountUpdate?: (count: number) => void
  isActive?: boolean
}

const SchoolTable = ({ search, onCountUpdate, isActive = true }: Props) => {
  const { partner } = useContext(GlobalContext)

  const [page, setPage] = React.useState(1)
  const [sortBy, setSortBy] = useState<string[]>(["-id"])
  const [filters, setFilters] = useState<TableFilter[]>([])

  const { setListener: addEventHandler, removeListener: removeEventListener } =
    useCustomEvent("schoolList")

  useEffect(() => {
    const resetTable = () => {
      setPage(1)
      setSortBy(["-id"])
      setFilters([])
      refetch()
    }

    addEventHandler(resetTable)

    return () => {
      removeEventListener(resetTable)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    data: schoolList,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    keepPreviousData: true,
    queryKey: queries.partner.schoolList({
      page,
      ordering: sortBy,
      search,
      filters: filters.map(f => [f.id, f.value].join("-")),
    }).queryKey,
    queryFn: () => {
      const addedByFilters = filters.filter(
        ({ id }) => id === "added_by_filter"
      )

      return api.partner.dashboard.schoolList({
        params: {
          page,
          search,
          ordering: sortBy,
          added_by_filter: addedByFilters.map(({ value }) => value),
          fields: [
            "number_of_teachers",
            "country",
            "state",
            "city",
            "added_by_partner",
            "referral_code",
          ],
        },
      })
    },

    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  useEffect(() => {
    if (onCountUpdate) onCountUpdate(schoolList?.total || 0)
  }, [schoolList])

  const navigate = useNavigate()

  const isSchool =
    partner?.partnerType === PartnerTypeEnum.SCHOOL_REPRESENTATIVE
  const isPartnerRep =
    partner?.partnerType === PartnerTypeEnum.PARTNER_REPRESENTATIVE

  useEffect(() => {
    if (partner?.partnerType === PartnerTypeEnum.SCHOOL_REPRESENTATIVE) {
      if (schoolList?.total === 1 && schoolList?.data.length === 1) {
        navigate(getLink(schoolList?.data[0]), {
          state: { disableBackButton: true },
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schoolList, partner?.partnerType])

  if (!isActive) return null

  if (isError || !schoolList) {
    return <Fallback hasError={isError} className="mt-5" />
  }

  return (
    <>
      {schoolList.data && (
        <TableWrapper
          content={className => (
            <Table
              loading={isFetching}
              className={className}
              data={schoolList.data}
              headers={[
                {
                  title: "School Name",
                  id: "name",
                  sortable: true,
                  columnComponent: ({ row }) => (
                    <td className="flex flex-col">
                      <Typography
                        color="onSurface.800"
                        variant="strongSmallBody"
                      >
                        {row.name}
                      </Typography>
                      <Typography color="onSurface.800" variant="smallBody">
                        {row.address}
                      </Typography>
                    </td>
                  ),
                },
                ...(!(isSchool || isPartnerRep)
                  ? [
                      {
                        title: "Added By",
                        id: "added_by_filter",
                        filters: [
                          {
                            displayName: "Added by me",
                            value: "self",
                          },
                          {
                            displayName: "Added by my team",
                            value: "sales_representative",
                          },
                          {
                            displayName: "Not added by me",
                            value: "system",
                          },
                        ],
                        // @ts-expect-error ts-issue
                        columnComponent: ({ row }) => (
                          <td>
                            <Typography
                              color="onSurface.800"
                              variant="smallBody"
                            >
                              {row.addedByPartner || "Not added by you"}
                            </Typography>
                          </td>
                        ),
                      },
                    ]
                  : []),
                {
                  title: "No. of Teachers",
                  id: "number_of_teachers",
                  columnComponent: ({ row }) => (
                    <td>
                      <Typography color="onSurface.500" variant="body">
                        {row.numberOfTeachers}
                      </Typography>
                    </td>
                  ),
                },
                {
                  align: "right",
                  title: "",
                  id: "action",
                  columnComponent: ({ row }) => (
                    <td>
                      {row.addedByPartner ? (
                        <IconButton
                          variant="filled"
                          className="!rounded-full"
                          component={Link}
                          to={getLink(row)}
                        >
                          <ArrowRight width={22} height={20} />
                        </IconButton>
                      ) : null}
                    </td>
                  ),
                },
              ]}
              onFilter={setFilters}
              multiSort={false}
              onSort={cols => {
                if (cols.length > 0) {
                  setSortBy(cols)
                } else {
                  setSortBy(["-id"])
                }
              }}
            />
          )}
        />
      )}
      {schoolList.data && !isFetching && schoolList.data.length === 0 && (
        <NoDataCard />
      )}
      {schoolList && schoolList.total > 10 && (
        <Pagination
          className="mt-5 mb-9"
          page={page}
          total={schoolList.total}
          onChange={setPage}
        />
      )}
    </>
  )
}

export default SchoolTable
