import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import { Registration } from "../registrations/types"
import { Overview, PaymentDetails, ValidateResponse } from "./types"
import { urls } from "./urls"

export default {
  alreadyRegistered: {
    get: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<Registration[]>> => {
      try {
        const res = await axios.get(urls.registrations.alreadyRegistered(), {
          params,
        })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  create: async ({
    data,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<ValidateResponse>> => {
    try {
      const res = await axios.post(
        urls.registrations.base(urlParams.olympiadSchoolSlug),
        {
          data,
        }
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  deletePartnerRegistration: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.delete(
        urls.registrations.base(urlParams.olympiadSchoolSlug)
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  overview: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<Overview>> => {
    try {
      const res = await axios.get(
        urls.registrations.base(urlParams.olympiadSchoolSlug)
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  getPaymentLink: async ({
    urlParams,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<PaymentDetails>> => {
    try {
      const res = await axios.post(
        urls.payment.getLink(urlParams.olympiadSchoolSlug),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  listRegisteredUsers: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponseOld<ValidateResponse>> => {
    try {
      const res = await axios.get(
        urls.registrations.list(urlParams.olympiadSchoolSlug)
      )
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
}
