import axios from "api/axios"
import { APIResponseOld, Config, PaginatedAPIResponse } from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  PartnerOverview,
  Registration,
  SchoolRegistration,
  StateRegistration,
} from "./types"
import { urls } from "./urls"

export default {
  list: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Registration[]>> => {
    try {
      const res = await axios.get(urls.registrations.list(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  downloadCSV: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<Registration[]>> => {
    try {
      const res = await axios.get(urls.registrations.downloadCSV(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  state: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<StateRegistration[]>> => {
    try {
      const res = await axios.get(urls.registrations.stateAnalytics(), {
        params,
      })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  school: async ({
    params,
  }: Config): Promise<PaginatedAPIResponse<SchoolRegistration[]>> => {
    try {
      const res = await axios.get(urls.registrations.schools(), { params })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  registrations: {
    getOverview: async (): Promise<APIResponseOld<PartnerOverview>> => {
      try {
        const res = await axios.get(urls.registrations.getOverview())
        return formatSuccessResponseOld(res)
      } catch (e) {
        return formatErrorResponseOld(e)
      }
    },
  },
}
