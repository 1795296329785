import { Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import React, { useContext } from "react"

type Props = {
  name?: string | null
  bottomSlot?: React.ReactNode
  action?: React.ReactNode
}

const TitleBar = ({ name, bottomSlot, action }: Props) => {
  const { partner } = useContext(GlobalContext)

  const getTitle = () => {
    if (name) return name
    return partner?.name || "Partner"
  }

  return (
    <div
      className={clsx(
        "border-2 border-onSurface-200 shadow-[0_6px_15px_0_rgba(0,0,0,0.05)] mb-3.5",
        {
          "py-2": !bottomSlot,
          "pt-2": bottomSlot,
        }
      )}
    >
      <Container>
        <div className="flex flex-wrap items-center justify-between gap-1">
          <Typography variant="title2" color="onSurface.800">
            {getTitle()}
          </Typography>
          {action}
        </div>
        {bottomSlot}
      </Container>
    </div>
  )
}

export default TitleBar
