import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Theme,
  Typography,
  useMediaQuery,
} from "@suraasa/placebo-ui"
import api from "api"
import {
  Overview,
  PaymentDetails,
} from "api/resources/multiple-registration/types"
import { PartnerTypeEnum } from "api/resources/registrations/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import { GlobalContext } from "components/GlobalState"
import CopyLink from "components/PartnerLinks/CopyLink"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { CheckCircledOutline, WarningCircledOutline } from "iconoir-react"
import { useContext, useState } from "react"
import { createUseStyles, useTheme } from "react-jss"
import { useLocation } from "react-router"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { handleErrors, pluralize } from "utils/helpers"

type Props = {
  onCancel: () => void

  data: Overview
  alreadyRegisteredCount: number
  toBeRegisteredCount: number
  retryPayment?: boolean
  schoolSlug?: string | null
}

const useStyles = createUseStyles(theme => ({
  successBannerContainer: {
    background: theme.colors.success[50],
    borderRadius: 8,
    border: `1px solid ${theme.colors.success[400]}`,
    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.05)",
  },
  completeButton: {
    backgroundColor: `${theme.colors.success[500]} !important`,
  },
  retryBannerContainer: {
    background: theme.colors.warning[50],
    borderRadius: 8,
    borderColor: theme.colors.warning[400],
  },
  retryButton: {
    backgroundColor: `${theme.colors.warning[600]} !important`,
  },
}))

const Payment = ({
  data,
  onCancel,
  alreadyRegisteredCount,
  toBeRegisteredCount,
  retryPayment = false,
  schoolSlug,
}: Props) => {
  const classes = useStyles()
  const theme = useTheme<Theme>()
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))

  const context = useContext(GlobalContext)
  const isSchool =
    context.partner?.partnerType === PartnerTypeEnum.SCHOOL_REPRESENTATIVE

  const location = useLocation()

  const [open, setOpen] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetails | null>(
    null
  )
  const [loading, setLoading] = useState(false)

  const toggle = () => {
    setOpen(prevState => !prevState)
  }

  const handlePayment = async () => {
    if (!schoolSlug) return
    setLoading(true)
    try {
      const res = await api.multipleRegistration.getPaymentLink({
        urlParams: {
          olympiadSchoolSlug: schoolSlug || "",
        },
        data: {
          callbackUrl: `${location.pathname}${location.search}`,
        },
      })
      setLoading(false)
      toggle()
      setPaymentDetails(res)
    } catch (e) {
      setLoading(false)
      if (e instanceof APIError) handleErrors(e)
    }
  }

  const { symbol } = data.currency
  const { pricePerTeacher } = data

  const hasProbablyPaid =
    !retryPayment &&
    localStorage.getItem(BROWSER_STORAGE_KEYS.paymentInitiated) === "true"

  return (
    <div>
      <Dialog open={open} width="sm" onRequestClose={toggle}>
        <DialogTitle>Confirm Details</DialogTitle>
        <DialogContent>
          <Typography>
            Please make sure all the details you entered are correct, as you
            won’t be able to change these details afterwards.
          </Typography>
          <Divider className="my-2" />
          <Typography variant="title3">Amount Breakdown</Typography>
          <div className="mb-8">
            <div
              className="mt-1.5 flex justify-between px-2 py-1"
              style={{ background: "#F1F5F9", borderRadius: 8 }}
            >
              {paymentDetails && (
                <Typography>
                  {pluralize("teacher", paymentDetails.unregisteredUsersCount)}{" "}
                  x {symbol}
                  {pricePerTeacher}
                </Typography>
              )}
              <Typography variant="strong">
                {symbol}
                {paymentDetails?.totalAmount}
              </Typography>
            </div>
            {hasProbablyPaid && (
              <Typography
                className="flex items-center gap-1 mt-1.5"
                color="critical.500"
                display="initial"
                variant="strongSmallBody"
              >
                <div>
                  <WarningCircledOutline />
                </div>
                Note: If you have already paid then please refresh this page or
                wait for a few hours. It might take some time to reflect on your
                dashboard.
              </Typography>
            )}
          </div>
        </DialogContent>

        <Divider />
        <div className="flex gap-1 p-1">
          {!isSchool && (
            <CopyLink
              link={paymentDetails?.paymentLink || ""}
              variant="outlined"
              label="Copy Link"
            />
          )}
          <Button
            fullWidth
            component="a"
            href={paymentDetails?.paymentLink}
            onClick={() => {
              localStorage.setItem(
                BROWSER_STORAGE_KEYS.paymentInitiated,
                "true"
              )
            }}
          >
            Pay {symbol}
            {paymentDetails?.totalAmount}
          </Button>
        </div>
      </Dialog>
      {loading && <LoadingOverlay opacity={0.3} />}
      <div style={{ textAlign: "end" }}>
        <Button color="critical" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
      </div>
      <div
        className={clsx(
          "px-4 py-3 flex justify-between mt-2 items-center flex-col sm:flex-row",
          classes.successBannerContainer,
          { [classes.retryBannerContainer]: retryPayment }
        )}
      >
        <div>
          <Typography
            className="flex items-center gap-1"
            color={retryPayment ? "warning.900" : "success.900"}
            display="initial"
            variant="title2"
          >
            Continue to Register Teachers
            {!retryPayment && (
              <span>
                <CheckCircledOutline
                  color={theme.colors.success[500]}
                  height={20}
                  width={20}
                />
              </span>
            )}
          </Typography>

          {!retryPayment && (
            <Typography
              className="mt-0.5"
              color="common.black.500"
              variant="largeBody"
            >
              Pay registration fee of {symbol}
              {pricePerTeacher}/teacher for{" "}
              {pluralize("teacher", toBeRegisteredCount)} of your school.
            </Typography>
          )}
          {alreadyRegisteredCount > 0 && (
            <Typography
              color="onSurface.500"
              variant="smallBody"
              className="mt-0.5"
            >
              Note: {pluralize("teacher", alreadyRegisteredCount)}{" "}
              {pluralize("has", alreadyRegisteredCount, {
                skipCount: true,
                plural: "have",
              })}{" "}
              already registered, hence you don’t have to pay for them.
            </Typography>
          )}
          {retryPayment && (
            <Typography
              className="mt-1"
              color="common.black.500"
              display="initial"
              style={{ maxWidth: "80%" }}
              variant="smallBody"
            >
              Your previous payment failed. If the amount was debited from your
              account it will be refunded within a few hours. If the issue
              persists please reach out to us at{" "}
              <a href="mailto:care@suraasa.com">care@suraasa.com</a>.{" "}
            </Typography>
          )}
          {hasProbablyPaid && (
            <Typography
              className="flex items-center gap-1 mt-1.5"
              color="critical.500"
              display="initial"
              style={{ maxWidth: "80%" }}
              variant="strongSmallBody"
            >
              <WarningCircledOutline className="hidden md:block shrink-0" />
              Note: If you have already paid then please refresh this page or
              wait for a few hours. It might take some time to reflect on your
              dashboard.
            </Typography>
          )}
        </div>

        <Button
          className={clsx("mt-2 sm:mt-0", classes.completeButton, {
            [classes.retryButton]: retryPayment,
          })}
          fullWidth={isXs}
          onClick={handlePayment}
        >
          {retryPayment ? "Retry Payment" : "Complete Payment"}
        </Button>
      </div>
    </div>
  )
}

export default Payment
