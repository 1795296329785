import Axios, { AxiosError, AxiosRequestConfig } from "axios"
import { getAuthInfo } from "utils/auth"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/constants"

import { refreshToken } from "./refreshToken"
import {
  logAPIError,
  paramsSerializer,
  transformRequest,
  transformResponse,
} from "./utils"

const axios = Axios.create({
  baseURL: import.meta.env.VITE_API_ENDPOINT,
  withCredentials: true,
  paramsSerializer,
  transformRequest: [
    transformRequest,
    ...(Array.isArray(Axios.defaults.transformRequest)
      ? Axios.defaults.transformRequest
      : []),
  ],
  transformResponse: [
    ...(Array.isArray(Axios.defaults.transformResponse)
      ? Axios.defaults.transformResponse
      : []),
    transformResponse,
  ],
  headers: {
    "Content-Type": "application/json",
    Language: "en",
  },
})

const authorizationIsEmpty = (request: AxiosRequestConfig) =>
  /**
   * If we send null in Authorization header from where the API is being called, axios transforms it into an empty object `{}`
   * That is why we are checking for object length and not null
   */
  request.headers.Authorization != null &&
  Object.keys(request.headers.Authorization).length === 0

axios.interceptors.request.use(request => {
  const authInfo = getAuthInfo()

  /**
   * Empty "Authorization" header means that we don't want to send this Authorization in the request even if accessToken is present in the localStorage
   * Example: in refresh token API call, we explicitly send Authorization: null from the API call.
   */
  if (authorizationIsEmpty(request)) {
    delete request.headers.Authorization
  } else if (authInfo) {
    const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${authInfo.accessToken}`
    request.headers.Authorization = tokenString
  }

  request.headers.Language = "en"

  return request
})

let tokenIsRefreshing = false
const interceptor = async (error: AxiosError) => {
  if (error.response) {
    if (error.response.status === 500) {
      logAPIError(error)
    }

    if (error.response.status === 401) {
      if (tokenIsRefreshing) {
        return Promise.reject(error)
      }

      tokenIsRefreshing = true
      await refreshToken()
      tokenIsRefreshing = false

      // Reject the current request while the token refreshes
      return Promise.reject(error)
    }
  } else {
    return Promise.reject(error)
  }
  return Promise.reject(error)
}

axios.interceptors.response.use(undefined, interceptor)

export default axios
