import {
  Avatar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { NavbarProps } from "components/shared/Navbar/types"
import { LogOut } from "iconoir-react"
import React from "react"
import { clearAuthInfo, getAuthInfo } from "utils/auth"
import { getPlatformURL } from "utils/helpers"

import styles from "./Navbar.module.css"
import NavLogo from "./NavLogo"

const Navbar = ({
  gutterBottom = true,
  slotEnd,
  bottomShadow = false,
}: NavbarProps) => {
  const authInfo = getAuthInfo()
  const isLoggedIn = Boolean(authInfo)

  const logout = () => {
    clearAuthInfo()

    // Logout from web-accounts
    const url = new URL(getPlatformURL("sso", "/partner/logout/"))
    url.searchParams.append("origin", window.location.origin)
    window.location.href = url.href
  }

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
          [styles.bottomShadow]: bottomShadow,
        })}
      >
        <nav className={clsx(styles.root, "py-1 flex items-center")}>
          <Container className="w-full">
            <div
              className={clsx(
                "flex justify-between items-center w-full flex-wrap",
                styles.menuWrapper
              )}
            >
              <NavLogo href="/dashboard" />

              {/* End */}
              <div className="flex items-center gap-1">{slotEnd}</div>
              {isLoggedIn && (
                <div>
                  <Menu
                    align="end"
                    menuButton={
                      <IconButton size="md">
                        <Avatar
                          color="two"
                          name={[
                            authInfo?.user.firstName,
                            authInfo?.user.lastName,
                          ]
                            .filter(Boolean)
                            .join(" ")}
                          src={undefined}
                        />
                      </IconButton>
                    }
                  >
                    <MenuItem
                      color="critical"
                      startAdornment={<LogOut />}
                      onClick={logout}
                    >
                      Log out
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default Navbar
