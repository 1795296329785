import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getNormaURL = getServiceURL(SERVICE_PREFIX.norma)

export const urls = validateUrls({
  registrations: {
    alreadyRegistered: () => getNormaURL("/v1/partners/registered-users/"),

    base: olympiadSchoolSlug =>
      getNormaURL(`/v1/partners/schools/${olympiadSchoolSlug}/registrations/`),

    list: olympiadSchoolSlug =>
      getNormaURL(
        `/v1/partners/schools/${olympiadSchoolSlug}/registrations/data/`
      ),
  },
  payment: {
    getLink: olympiadSchoolSlug =>
      getNormaURL(
        `/v1/partners/schools/${olympiadSchoolSlug}/registrations/payment/`
      ),
  },
})
