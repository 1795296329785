import { Pagination, Table, Typography } from "@suraasa/placebo-ui"
import api from "api"
import { Registration } from "api/resources/registrations/types"
import { PaginatedAPIResponse } from "api/types"
import NoDataCard from "components/shared/NoDataCard"
import TableWrapper from "components/shared/TableWrapper"
import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { handleErrors } from "utils/helpers"

type Props = {
  search: string
  onCountUpdate: (count: number) => void
  isActive: boolean
}

const CompletedRegistrations = ({ search, onCountUpdate, isActive }: Props) => {
  // const { loading: downloading, download } = useDownloadCSV({
  //   fileName: "All Registrations.csv",
  //   apiProps: {
  //     params: {
  //       registration_fee_paid: true,
  //     },
  //   },
  // })

  const [page, setPage] = React.useState(1)
  const [loading, setLoading] = useState(true)
  const [sortBy, setSortBy] = useState<string[]>([])
  const [registrations, setRegistrations] = useState<
    PaginatedAPIResponse<Registration[]>
  >({
    data: [],
    nextPage: null,
    previousPage: null,
    total: 0,
  })

  useEffect(() => {
    const fetchRegistrations = async () => {
      const res = await api.registrations.list({
        params: {
          page,
          search,
          registration_status: "completed",
          ordering: sortBy,
          fields: [
            "email",
            "phone_number",
            "olympiad_school.name",
            "state.name",
            "registration_date",
          ],
        },
      })

      if (!res.errors) {
        setRegistrations({
          data: res.data,
          nextPage: res.nextPage,
          previousPage: res.previousPage,
          total: res.total,
        })
      } else {
        handleErrors(res.errors)
      }

      setLoading(false)
    }
    fetchRegistrations()
  }, [page, search, sortBy])

  useEffect(() => {
    setPage(1)
  }, [search])

  useEffect(() => {
    onCountUpdate(registrations?.total || 0)
  }, [registrations])

  if (!isActive) return null

  return (
    <>
      <div className="flex justify-end">
        {/* <Button
          loading={downloading}
          disabled={loading || registrations.total === 0}
          startAdornment={<Download />}
          variant="text"
          onClick={download}
        >
          Download CSV
        </Button> */}
      </div>
      <TableWrapper
        content={className => (
          <Table
            loading={loading}
            className={className}
            data={registrations.data}
            headers={[
              {
                title: "Name",
                id: "full_name",
                align: "left",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography variant="strong">{row.fullName}</Typography>
                  </td>
                ),
              },
              {
                title: "Email",
                id: "email",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.email}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Phone Number",
                id: "phone_number",
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.phoneNumber}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "School Name",
                id: "olympiad_school__name",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {(row.olympiadSchool && row.olympiadSchool.name) ?? "-"}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "State",
                id: "state__name",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {(row.state && row.state.name) ?? "-"}
                    </Typography>
                  </td>
                ),
              },
              {
                title: "Date Registered",
                id: "registration_date",
                sortable: true,
                columnComponent: ({ row }) => (
                  <td>
                    <Typography color="onSurface.500" variant="body">
                      {row.registrationDate
                        ? format(new Date(row.registrationDate), "d MMMM yyyy")
                        : "-"}
                    </Typography>
                  </td>
                ),
              },
            ]}
            multiSort={false}
            onSort={cols => setSortBy(cols)}
          />
        )}
      />
      {!loading && registrations.data.length === 0 && <NoDataCard />}

      {registrations.total > 10 && (
        <Pagination
          className="mt-5 mb-9"
          page={page}
          total={registrations.total}
          onChange={setPage}
        />
      )}
    </>
  )
}

export default CompletedRegistrations
