import { AuthData } from "api/resources/users/types"
import { transformKeys } from "api/utils"
import camelCase from "lodash/camelCase"
import { BROWSER_STORAGE_KEYS, Platforms } from "utils/constants"

export function saveAuthInfo(authInfo: AuthData) {
  const newAuthInfoObj: AuthData = transformKeys(authInfo, camelCase)
  localStorage.setItem(
    BROWSER_STORAGE_KEYS.auth,
    JSON.stringify(newAuthInfoObj)
  )
}

export function getAuthInfo() {
  const authInfo = localStorage.getItem("auth")

  if (authInfo !== null) {
    const newAuthInfoObj: AuthData = transformKeys(
      JSON.parse(authInfo),
      camelCase
    )
    return newAuthInfoObj
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return authInfo
}

export function clearAuthInfo() {
  const { auth, paymentInitiated } = BROWSER_STORAGE_KEYS
  localStorage.removeItem(auth)
  localStorage.removeItem(paymentInitiated)
  sessionStorage.removeItem(auth)
}

export const isValidPlatform = (
  platform: string | null,
  validPlatformList = Object.values(Platforms)
): platform is Platforms =>
  platform ? validPlatformList.includes(platform as Platforms) : false

export const getPlatform = (
  platform: string | null | undefined,
  validPlatformList?: Platforms[]
) => {
  if (!platform) return null

  return isValidPlatform(platform, validPlatformList) ? platform : null
}

export const openSSOPopup = () => {
  const width = 500
  const height = 600
  const pos = {
    x: window.innerWidth / 2 - width / 2,
    y: window.innerHeight / 2 - height / 2,
  }

  const url = `${import.meta.env.VITE_SSO_URL}/popup-sso?platform=${
    Platforms.olympiadPartner
  }&origin=${window.location.origin}`
  const settings = `width=${width} height=${height} left=${pos.x} top=${pos.y}`
  window.open(url, "accounts-sso", settings)
}

export const getSSOURL = () =>
  `${import.meta.env.VITE_SSO_URL}/partner/login?redirect-url=${
    window.location.href
  }`
