import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import React from "react"
import { createUseStyles } from "react-jss"
import customTheme from "utils/customTheme"

const useStyles = createUseStyles(() => ({
  overviewBanner: {
    background: customTheme.iris[100],
    color: customTheme.iris[900],
    borderRadius: 8,
  },
}))

const AlreadyRegisteredBanner = ({ onCancel }: { onCancel: () => void }) => {
  const classes = useStyles()

  return (
    <>
      <div style={{ textAlign: "end" }}>
        <Button variant="outlined" onClick={onCancel}>
          Upload Another CSV
        </Button>
      </div>
      <div
        className={clsx(
          "px-4 py-3 mt-2 flex items-center justify-between",
          classes.overviewBanner
        )}
      >
        <Typography variant="title2">
          All the teachers that you have entered are already registered. 🎉
        </Typography>
      </div>
    </>
  )
}

export default AlreadyRegisteredBanner
